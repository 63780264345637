import inRange from "lodash/inRange";

export const tCellRangeColor = (value, resultScale = null) => {
  if (!resultScale || !resultScale.result_scale_indices) return "";

  const sortedIndices = [...resultScale.result_scale_indices].sort((a, b) => a.bottom - b.bottom);

  const classColors = [
    "tCellRed",
    "tCellRedLight",
    "tCellOrange",
    "tCellYellow",
    "tCellGreenDarker",
    "tCellGreen",
  ];
  const dynamicClassNames = classColors.slice(0, sortedIndices.length);

  const validationRange = sortedIndices.map(
    (scale) => inRange(value / 100, scale.bottom, scale.top) || value / 100 === scale.top,
  );

  if (value / 100 >= sortedIndices[sortedIndices.length - 1]?.top) {
    validationRange.push(true);
  }

  const validIndex = validationRange.indexOf(true);
  return validIndex >= 0 ? dynamicClassNames[validIndex] : "";
};

export const tCellOdd = (index) => (index % 2 === 0 ? "tCellWhite" : "tCellOdd");

