import styled from "styled-components";
import palette, { EVALUATION_COLOR } from "theme/palette";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

export const StyledTabs = styled(Tabs)`
  display: flex;
  .MuiTabs-flexContainer {
    display: flex;
    align-items: stretch;
  }
  .MuiTabs-indicator {
    height: 0;
  }
  button.MuiTab-root {
    padding: 0;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 20px;
  width: 100%;
`;

export const StyledText = styled(Typography)`
  font-weight: 700;
  color: ${(props) => EVALUATION_COLOR[props.status]?.color || "#000000"};
  text-align: center;
  word-break: break-word;
  white-space: normal;
`;

export const StyledProgressText = styled(Typography)`
  font-weight: 500;
  color: ${(props) => EVALUATION_COLOR[props.status]?.color || "#000000"};
`;

export const StyledTab = styled(Tab)`
  min-width: auto;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 10px;

  &.Mui-selected {
    background: ${(props) => EVALUATION_COLOR[props.isCompleted ? "completed" : "in_progress"]?.background};
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  background-color: #dadbf1;
  width: 100%;
  margin-top: auto;
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => (props.isCompleted ? EVALUATION_COLOR.completed.background : palette.background.cyan)};
  }
`;
