import { GOOD_LEADER_COLOR_SCALE, OBJECT_KEYS } from "common/constants";
import { isNull, orderByDesc } from "common/helpers";

export const getScaleWithColors = (scale) => orderByDesc(scale, OBJECT_KEYS.top)
  .map((item, index) => ({
    ...item,
    color: GOOD_LEADER_COLOR_SCALE[index],
  }));

export const isActiveDataResult = (
  isActiveResult,
  performanceData,
  isLoading,
  isLoadingProfile,
) => (
  isActiveResult && !isNull(performanceData) && !isLoading && !isLoadingProfile
);
