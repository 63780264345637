import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";
import ClearIcon from "@mui/icons-material/Clear";

export const StyledChip = styled(Chip)`
  background-color: ${(props) => props.background || palette.background.iconBtn } !important;
  color: ${(props) => (props.textcolor || (props.blacktextcolor ? palette.black : palette.white))};
  margin-right: ${(props) => props.marginRight || "0"};
  padding: 0px;
  font-size: 10px;
  font-weight: 500;
  height: auto;
  min-width: ${(props) => props.minWidth || "100px" };
`;

export const StyledResult = styled.div`
  text-align:center;
`;

export const StyledContainer = styled.div`
  table {
    background: ${palette.background.default};
  }
  table tr th, table tr td {
    font-size: 12px;
    line-height: 15px;
  }
  table tr th:first-child, table tr td:first-child {
    padding: 0 15px;
  }
  .MuiSelect-select {
    padding: 10px;
  }
  .MuiFormControl-root {
    margin-top: 10px;
    background: white;
    border: none;
    .MuiInputBase-root {
      padding: 5px 10px;
      textarea {
        font-size: 12px;
        color: #545454;
        line-height: 16px;
      }
    }
  }
`;

export const StyledDescription = styled.div`
  background: #E6EBF3;
  margin: 10px 0;
  padding: 10px 20px;
  border-radius: 4px;
  white-space: pre-line;
  p, h1, h2, h3, h4, h5, h6 {
    background: none;
    margin: 0;
  }
`;

export const StyledCollapseData = styled.div`
  padding: 10px 0px;
  h4 {
    margin: 10px 0;
  }
`;

export const StyledLabel = styled(Typography)`
  margin-top: 10px;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 5px;
  span {
    font-weight: 400;
    font-size: 12px;
    color: ${palette.text.grayDark};
  }
`;

export const FileInputLabel = styled.label`
  background: white;
  display: flex;
  justify-content: end;
  align-items: center;
  color: #0000FF;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  padding: 10px;
  margin-bottom: 10px;
  &:hover {
    text-decoration: none;
  }
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const StyledAttachment = styled.a`
  display: flex;
  align-items: center;
  color: #0000FF;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const StyledDeleteIcon = styled(ClearIcon)`
  color: ${palette.table.red};
  width:  15px;
  height:  15px;
`;

export const StyledTitle = styled.h4`
  margin-bottom: 5px;
`;

export const StyledGridForm = styled(Grid)`
  .MuiInputAdornment-root p {
    color: ${palette.background.lightBlue};
    font-size: 28px;
    font-weight: 500;
  }
`;

export const StyledGridMobile = styled(Grid)`
  text-align-last: start;
  background: ${palette.background.default};
  border-radius: 5px;
  padding: 10px;
  h4 {
    margin: 6px 2px 4px 0;
  }
  p {
    font-size: 12px;
  }
`;

export const StyledTitleTable = styled.div`
  padding: 5px;
`;
