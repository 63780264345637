import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import NoDataMessage from "components/NoDataMessage";
import Button from "components/Button";
import Banner from "components/Banner";
import TabPanel from "components_refactor/TabPanel";
import ProgressTab from "components_refactor/ProgressTab";
import { isSimpleTypeEvaluation } from "views/SurveyEvaluationsPreview/functions";
import {
  StyledContainer,
  StyledContent,
} from "views/SurveyEvaluationsPreview/styles";
import GoalPreviewImage from "assets/images/forms/goal_evaluation_preview.svg";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getAvatar } from "common/utils";
import { STATUS_KEYS } from "common/constants";
import { createTab } from "../../functions";
import ResponsiveQuestions from "../ResponsiveQuestions";
import TeamEvaluation from "./TeamEvaluation";
import SingleEvaluation from "./SingleEvaluation";
import {
  StyledEvaluationTitle,
  StyledImage,
  StyledTitle,
  StyledMember,
  StyledEvaluated,
  StyledTabsContainer,
  StyledGoalContainer,
  StyledPreviewContent,
  StyledBoxWithBorders,
  FloatingText,
  StyledGoalImage,
} from "../../styles";
import { StyledStepButtons } from "./styles";

const EvaluationContent = (props) => {
  const {
    evaluation,
    evaluationScales,
    control,
    handleModalSubmit,
    isDisabled,
    handleSendDataByQuestion,
    evaluationResult,
    isMobile,
    processId,
    processData,
    handleSubmit,
    slideAnswers,
    setSlideAnswers,
    loadingProcess,
    evaluationFormStates,
    t,
    handleToCoverOrHomeProcess,
    handleNextRef,
  } = props;

  const { oneProcess: surveyProcess } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  const [teamAnswers, setTeamAnswers] = useState({});
  const [isConfirmPage, setIsConfirmPage] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showInitialInfo, setShowInitialInfo] = useState(null);

  const [evaluationProgress, setEvaluationProgress] = useState({
    completedSteps: 0,
    totalSteps: 0,
    progress: 0,
    status: t("surveys:goals.status.not_started"),
    statusKey: STATUS_KEYS.NOT_STARTED,
    isCompleted: false,
    isValidToSubmit: false,
  });

  const membersWithGoals = evaluationFormStates?.teamMembers?.filter(
    (member) => member.evaluationResult
      && member.evaluationResult.goal_evaluations.length > 0,
  );

  const [goalProgressState, setGoalProgressState] = useState({
    progress: 0,
    status: t("surveys:goals.status.not_started"),
    statusKey: STATUS_KEYS.NOT_STARTED,
    isCompleted: false,
    initial: true,
  });

  const updateGoalProgress = (updatedMembers = null, updatedMember = null) => {
    const currentMembers = updatedMembers || membersWithGoals;
    const currentMember = updatedMember || evaluationResult;

    let totalGoals = 0;
    let evaluatedGoals = 0;

    if (currentMember?.goal_evaluations?.length > 0) {
      totalGoals = currentMember.goal_evaluations.length;
      evaluatedGoals = currentMember.goal_evaluations.filter(
        (goal) => goal.score !== null && goal.score !== undefined,
      ).length;
    } else if (currentMembers?.length > 0) {
      totalGoals = currentMembers.reduce(
        (sum, member) => sum + member.evaluationResult.goal_evaluations.length,
        0,
      );
      evaluatedGoals = currentMembers.reduce(
        (sum, member) => sum
          + member.evaluationResult.goal_evaluations.filter(
            (goal) => goal.score !== null && goal.score !== undefined,
          ).length,
        0,
      );
    }

    const progress = totalGoals > 0 ? (evaluatedGoals / totalGoals) * 100 : 0;

    if (showInitialInfo === null) {
      setShowInitialInfo(progress === 0);
    }

    let status = t("surveys:goals.status.not_started");
    let statusKey = STATUS_KEYS.NOT_STARTED;
    let isCompleted = false;

    if (progress === 0) {
      statusKey = STATUS_KEYS.NOT_STARTED;
    } else if (progress > 0 && progress < 100) {
      status = t("surveys:goals.status.in_progress");
      statusKey = STATUS_KEYS.IN_PROGRESS;
    } else if (progress === 100) {
      status = t("surveys:goals.status.completed");
      statusKey = STATUS_KEYS.COMPLETED;
      isCompleted = true;
    }

    if (
      goalProgressState.progress !== progress
      || goalProgressState.status !== status
      || goalProgressState.statusKey !== statusKey
      || goalProgressState.isCompleted !== isCompleted
    ) {
      setGoalProgressState({
        progress,
        status,
        statusKey,
        isCompleted,
        initial: false,
      });
    }
  };

  useEffect(() => {
    if (goalProgressState.initial) {
      updateGoalProgress();
    }
    // eslint-disable-next-line
  }, [evaluationResult, membersWithGoals]);

  const { itemData, label } = processData || {};

  const evaluationTab = createTab({
    title: surveyProcess?.[itemData]?.survey_process_template_name || label,
    status: evaluationProgress.status,
    statusKey: evaluationProgress.statusKey,
    progress: evaluationProgress.progress,
    isCompleted: evaluationProgress.isCompleted,
  });

  const tabsData = [evaluationTab];
  const hasGoalEvaluation = evaluationResult?.goal_evaluations?.length > 0
  || membersWithGoals?.length > 0;

  if (hasGoalEvaluation) {
    const goalTab = createTab({
      title: t("surveys:goals.rate_goals"),
      status: goalProgressState.status,
      statusKey: goalProgressState.statusKey,
      progress: goalProgressState.progress,
      isCompleted: goalProgressState.isCompleted,
    });

    tabsData.push(goalTab);
  }

  const handleProgressUpdate = (
    closedQuestions,
    completedClosedQuestions,
    isValidToSubmit = false,
  ) => {
    const totalClosedQuestions = closedQuestions.length;
    const progress = (completedClosedQuestions / totalClosedQuestions) * 100;

    let status = t("surveys:goals.status.not_started");
    let statusKey = STATUS_KEYS.NOT_STARTED;
    let isCompleted = false;

    if (progress === 0) {
      status = t("surveys:goals.status.not_started");
      statusKey = STATUS_KEYS.NOT_STARTED;
    } else if (progress > 0 && progress < 100) {
      status = t("surveys:goals.status.in_progress");
      statusKey = STATUS_KEYS.IN_PROGRESS;
    } else if (progress === 100) {
      status = t("surveys:goals.status.completed");
      statusKey = STATUS_KEYS.COMPLETED;
      isCompleted = true;
    }

    setEvaluationProgress({
      completedSteps: completedClosedQuestions,
      totalSteps: totalClosedQuestions,
      progress,
      status,
      statusKey,
      isCompleted,
      isValidToSubmit,
    });
  };

  const handleConfirmPage = (state) => {
    setIsConfirmPage(state);
  };

  const getEvaluated = () => {
    if (
      !isSimpleTypeEvaluation(evaluation.type)
      && evaluationResult
      && !isConfirmPage
    ) {
      return (
        <StyledContainer>
          <StyledContent>
            <Grid container>
              <Grid item>
                {!isMobile && (
                  <StyledEvaluated>
                    {t("surveys:form.fields.evaluated")}
                  </StyledEvaluated>
                )}
                <StyledMember>
                  <Avatar
                    alt={ evaluationResult.evaluation_details.evaluated.name }
                    src={ getAvatar(evaluationResult.evaluation_details.evaluated) }
                  />
                  <Typography>
                    {evaluationResult.evaluation_details.evaluated.name}
                  </Typography>
                </StyledMember>
              </Grid>
            </Grid>
          </StyledContent>
        </StyledContainer>
      );
    }
    return null;
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (evaluationProgress.isValidToSubmit) {
      setEvaluationProgress((prevState) => ({
        ...prevState,
        isValidToSubmit: false,
      }));
      if (tabsData.length > 1) {
        setActiveTab(1);
      }
    }
  }, [
    evaluationProgress.isValidToSubmit,
    tabsData.length,
  ]);

  const getEvaluationContent = () => {
    let content = <NoDataMessage />;
    if (evaluation?.evaluation_sections.length > 0 && processData) {
      content = (
        <form
          id={ `evaluation-form-${processData.label}` }
          onSubmit={ handleSubmit(handleModalSubmit) }
        >
          {!isMobile && !processId && getEvaluated()}
          <ResponsiveQuestions
            evaluation={ evaluation }
            evaluationScales={ evaluationScales }
            control={ control }
            onSubmit={ handleModalSubmit }
            mobileAnswers={ slideAnswers }
            setMobileAnswers={ setSlideAnswers }
            teamAnswers={ teamAnswers }
            setTeamAnswers={ setTeamAnswers }
            isLoading={ loadingProcess }
            members={ evaluationFormStates.teamMembers }
            isDisabled={ isDisabled }
            handleSendDataByQuestion={ handleSendDataByQuestion }
            evaluationResult={ evaluationResult }
            handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
            handleConfirmPage={ handleConfirmPage }
            handleNextRef={ handleNextRef }
            onProgressUpdate={ handleProgressUpdate }
            hasGoalEvaluation={ hasGoalEvaluation }
          />
        </form>
      );
    }
    return content;
  };

  const goalSection = evaluation?.evaluation_sections.find((section) => section.section_type === "goals");

  const toggleInfoDisplay = () => {
    setShowInitialInfo(!showInitialInfo);
  };

  return (
    <>
      {isMobile ? (
        <StyledEvaluationTitle>
          <StyledTitle>
            <StyledImage
              src={ processData.icon }
              alt={ t(
                `common:common.evaluations.${evaluation?.type}.title`,
              ) }
            />
            {surveyProcess && surveyProcess[processData?.itemData]
              ? surveyProcess[processData?.itemData].name
              : ""}
            <br />
            {evaluation?.type === EVALUATION_TYPE.OVERALL
              ? ""
              : t(
                  `common:common.evaluations.${evaluation?.type}.title`,
              )}
          </StyledTitle>
          {!processId && !isConfirmPage && getEvaluated()}
        </StyledEvaluationTitle>
      ) : (
        <Banner
          title={ surveyProcess && surveyProcess[processData?.itemData]
            ? surveyProcess[processData?.itemData].name
            : "" }
          text={ evaluation?.type === EVALUATION_TYPE.OVERALL
            ? ""
            : t(
                  `common:common.evaluations.${evaluation?.type}.title`,
            ) }
          icon={ processData.icon }
        />
      )}
      <StyledTabsContainer>
        <ProgressTab
          tabs={ tabsData }
          tabValue={ activeTab }
          onChange={ handleTabChange }
        />
        <TabPanel value={ activeTab } index={ 0 }>
          {getEvaluationContent()}
        </TabPanel>
        <TabPanel value={ activeTab } index={ 1 }>
          <StyledGoalContainer isMobile={ isMobile }>
            {showInitialInfo ? (
              <>
                <Typography variant={ "h5" }>
                  {t(`surveys:goals.description.${evaluationResult ? "self" : "team"}`)}
                </Typography>
                <StyledPreviewContent isMobile={ isMobile }>
                  <StyledGoalImage
                    src={ GoalPreviewImage }
                    alt={ "Goal evaluation preview" }
                    isMobile={ isMobile }
                  />
                  {!isMobile && (
                    <StyledBoxWithBorders>
                      <FloatingText variant={ "h6" }>
                        {t(`surveys:goals.${evaluationResult ? "self" : "team"}_detail`)}
                      </FloatingText>
                    </StyledBoxWithBorders>
                  )}
                </StyledPreviewContent>

                <StyledStepButtons>
                  <Button
                    variant={ "outlined" }
                    type={ "text" }
                    onMouseDown={ handleToCoverOrHomeProcess }
                    typeStyle={ "outlined" }
                  >
                    { isMobile ? t("common:common.cancel") : t("surveys:continueLater") }
                  </Button>
                  {/* <Button
                    color={ "primary" }
                    variant={ "outlined" }
                    onMouseDown={ () => {} }
                    typeStyle={ "outlined" }
                  >
                    { t("common:common.previous") }
                  </Button> */}
                  <Button
                    variant={ "contained" }
                    color={ "primary" }
                    typeStyle={ "submit" }
                    onMouseDown={ toggleInfoDisplay }
                  >
                    { t("common:common.next") }
                  </Button>
                </StyledStepButtons>
              </>
            )
              : membersWithGoals ? (
                <TeamEvaluation
                  membersWithGoals={ evaluationFormStates?.teamMembers }
                  t={ t }
                  isMobile={ isMobile }
                  goalSection={ goalSection }
                  handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
                  updateGoalProgress={ updateGoalProgress }
                  evaluation={ evaluation }
                  type={ processData.dashboard }
                  isCompletedGoal={ goalProgressState.isCompleted }
                  isCompletedGeneral={ evaluationProgress.isCompleted }
                />
              ) : (
                <SingleEvaluation
                  t={ t }
                  member={ evaluationResult }
                  isMobile={ isMobile }
                  goalSection={ goalSection }
                  handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
                  updateGoalProgress={ updateGoalProgress }
                  evaluation={ evaluation }
                  type={ processData.dashboard }
                  isCompletedGoal={ goalProgressState.isCompleted }
                  isCompletedGeneral={ evaluationProgress.isCompleted }
                />
              )}
          </StyledGoalContainer>
        </TabPanel>
      </StyledTabsContainer>
    </>
  );
};

EvaluationContent.propTypes = {
  evaluation: PropTypes.object.isRequired,
  evaluationScales: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  handleModalSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleSendDataByQuestion: PropTypes.func.isRequired,
  evaluationResult: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  processId: PropTypes.number.isRequired,
  processData: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  slideAnswers: PropTypes.array.isRequired,
  setSlideAnswers: PropTypes.func.isRequired,
  loadingProcess: PropTypes.bool.isRequired,
  evaluationFormStates: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
  handleNextRef: PropTypes.object.isRequired,
};

export default EvaluationContent;
