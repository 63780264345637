import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toNumber } from "lodash";
import { getPropertyByLocation, createResultToSkillLevelMap } from "common/utils";
import {
  LOCATION_PROPS,
  SURVEY_PROCESS_TYPE,
  COMPANY_SLUGS,
} from "common/constants";
import { isNullOrEmpty } from "common/validators";
import paths from "common/paths";
import useComplexState from "hooks/utils/useComplexState";
import { getList as getPersonality } from "redux/actions/personalityActions";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { INDEX_TABS_SKILLS, setSkillCategoryAndTip, skillType } from "../functions/skills";
import localRoutes from "../functions/routes";

export const useSkillHook = (externalHooks, internalHooks) => {
  const {
    dispatch, location, user,
  } = externalHooks;
  const {
    collaborator, searchCollaborator,
    isValidToShowNineBox: isValidToShowResult,
    isOwnProfile,
  } = internalHooks;

  const history = useHistory();

  const [skillStates, setSkillStates] = useComplexState({
    tabValue: INDEX_TABS_SKILLS.skills,
    userSurveyResult: null,
    evaluationList: null,
    evaluationSelected: null,
  });

  if (user?.company?.slug === COMPANY_SLUGS.shn
    && location.pathname === localRoutes.skills
    && !isValidToShowResult) {
    history.push("/not-found");
  }
  // search process id by url
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processParam = locationSearch?.split(paths.process)[1];
  const processId = processParam ? locationSearch?.split("=")[2] : null;

  const {
    list: listPersonality,
    loadinglist: isLoadingListPersonality,
  } = useSelector(({ personalityReducer }) => personalityReducer);

  const {
    list: listPerformanceSurveyResults,
    isLoadingList: isLoadingPerformanceSurveyResults,
  } = useSelector(
    ({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer,
  );

  const hasAccessToViewPotential = !(
    isOwnProfile && !user.company.show_potential
  );
  const isSkillModalOpen = hasAccessToViewPotential && location.pathname === localRoutes.skills;
  const hasPersonalitySection = !isNullOrEmpty(listPersonality);

  useEffect(() => {
    setSkillStates({ tabValue: location?.state?.tabId || INDEX_TABS_SKILLS.skills });
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    // TODO: validates this for all users (no candidate)
    const employeeId = collaborator?.id;
    if (employeeId && isSkillModalOpen) {
      if ((collaborator?.id === searchCollaborator) && (listPerformanceSurveyResults === null)) {
        const query = {
          q: {
            score_not_null: 1,
            survey_process_type_eq: SURVEY_PROCESS_TYPE.potential.key,
            s: "end_date desc",
          },
        };
        dispatch(getIndividualSurveyResult(employeeId, query));
      }
      const personId = collaborator?.person?.id;
      dispatch(getPersonality(personId));
    }
    // eslint-disable-next-line
  }, [dispatch, collaborator, searchCollaborator, listPerformanceSurveyResults]);

  useEffect(() => {
    if (!isLoadingPerformanceSurveyResults
      && listPerformanceSurveyResults !== null
      && isSkillModalOpen
    ) {
      if (listPerformanceSurveyResults.length === 0) {
        setSkillStates({
          evaluationList: [],
          userSurveyResult: {},
        });
      } else {
        const list = listPerformanceSurveyResults.filter(
          (result) => result.score !== null,
        ).map((item) => ({
          value: item.survey_process?.id,
          label: item.survey_process?.name,
        }));
        const evaluationId = toNumber(processId) || list[0]?.value;
        setSkillStates({
          evaluationList: list,
          evaluationSelected: evaluationId,
        });
      }
    }
    // eslint-disable-next-line
  }, [
    processId,
    listPerformanceSurveyResults,
    isLoadingPerformanceSurveyResults,
  ]);

  const getDetail = useCallback((list) => {
    const resultSelected = list.find(
      (surveyResult) => surveyResult?.survey_process?.id === skillStates.evaluationSelected,
    );
    if (resultSelected) {
      if (resultSelected.result) {
        resultSelected.complete_results_by_section.forEach(((item) => {
          if (skillType?.includes(item.tag)) {
            const resultToSkillLevelMap = createResultToSkillLevelMap(resultSelected);
            const skillLevelName = resultToSkillLevelMap[item.result];
            setSkillCategoryAndTip(skillLevelName, item);
          }
        }));
      }
    }
    return resultSelected;
  }, [skillStates.evaluationSelected]);

  useEffect(() => {
    if (skillStates?.evaluationSelected !== null
      && listPerformanceSurveyResults
      && listPerformanceSurveyResults.length > 0
    ) {
      const resultData = getDetail(listPerformanceSurveyResults);
      setSkillStates({
        userSurveyResult: resultData,
      });
    }
    // eslint-disable-next-line
  }, [
    skillStates.evaluationSelected,
    listPerformanceSurveyResults,
  ]);

  const handleTabChange = (value) => {
    setSkillStates({
      tabValue: value,
    });
  };

  const validPersonalityData = () => (!isNullOrEmpty(listPersonality)
    && listPersonality.length > 0
    ? listPersonality
    : []);

  const handlePotentialChange = (prop, event) => {
    setSkillStates({
      evaluationSelected: event.target.value,
    });
  };

  return {
    skillStates,
    hasPersonalitySection,
    personality: {
      personalityData: validPersonalityData(),
      isLoadingListPersonality,
    },
    performanceSurvey: {
      listPerformanceSurveyResults,
      isLoadingPerformanceSurveyResults,
    },
    handleTabChange,
    handlePotentialChange,
  };
};
