import { STATE } from "common/constants";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { isNotValid, isNullOrUndefined } from "common/helpers";

export const getTabsByProcess = (evaluations, t) => {
  if (evaluations) {
    return evaluations.map((evaluation) => ({ label: t(`tables:headers.${evaluation.type}`) }));
  }
  return [];
};

export const isSimpleTypeEvaluation = (type) => EVALUATION_TYPE.AUTOEVALUATION === type || EVALUATION_TYPE.OVERALL === type;

const getAnswers = (questions, data, isOpen, evaluationResult) => {
  let lastAnswers = [];
  if (evaluationResult) {
    if (evaluationResult && evaluationResult.length > 0) {
      lastAnswers = evaluationResult.map((result) => result.answers.map((answer) => ({
        ...answer,
      }))).flat();
    }
  }
  const answers = [];
  questions.forEach((question) => {
    const questionId = question.id;
    const answer = data[questionId];
    const evalAnswer = lastAnswers ? lastAnswers.find(
      (lastAnswer) => lastAnswer.evaluation_question_id === questionId,
    ) : null;
    // FIXME: If it is an open-ended question and you wish
    // to leave it blank, do not submit null or empty.
    if (!isNotValid(answer)) {
      const answerObj = {
        evaluation_question_id: questionId,
        answer,
      };
      if (evalAnswer) {
        answerObj.id = evalAnswer.evaluation_answer_id;
      }
      answers.push(answerObj);
    }
  });
  return answers;
};

export const getEvaluationAnswers = (
  isDraft,
  data,
  evaluation,
  evaluatorId,
  evaluatedId,
  evaluationResult,
  hasDataToUpdate,
) => {
  const evaluated = EVALUATION_TYPE.AUTOEVALUATION === evaluation?.type ? evaluatorId : evaluatedId;

  const evaluationAttributes = [];

  evaluation?.evaluation_sections?.forEach((section) => {
    const evaluationSectionResultId = evaluationResult?.find(
      (evalResult) => evalResult.section_id === section.id,
    )?.evaluation_section_result_id;
    const answers = getAnswers(section.evaluation_questions, data, section.open, evaluationResult);
    if (answers.length > 0) {
      const evaluationAttributesObj = {
        evaluation_section_id: section.id,
        evaluation_answers_attributes: answers,
      };

      // Check if comment exists for the section
      if (data[`comment_${section.id}`]) {
        const sectionComment = data[`comment_${section.id}`] === "" ? null : data[`comment_${section.id}`];
        evaluationAttributesObj.comment = sectionComment;
      }

      // Add evaluationSectionResultId if it exists
      if (evaluationSectionResultId) {
        evaluationAttributesObj.id = evaluationSectionResultId;
      }

      evaluationAttributes.push(evaluationAttributesObj);
    }
  });

  const dataToSave = {
    evaluation_result: {
      evaluation_id: evaluation?.id,
      state_transition: isDraft ? STATE.draft : STATE.answer,
      evaluation_section_results_attributes: evaluationAttributes,
    },
  };

  // Check if overall comment exists
  if (!isNullOrUndefined(data.comment)) {
    dataToSave.evaluation_result.comment = data.comment === "" ? null : data.comment;
  }

  // Set evaluated_id if there is no data to update
  if (!hasDataToUpdate) {
    dataToSave.evaluation_result.evaluated_id = EVALUATION_TYPE.OVERALL !== evaluation.type ? evaluated : null;
  }

  return dataToSave;
};
