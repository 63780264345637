import PropTypes from "prop-types";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import StarRating from "components/StarRating";
import SkeletonLoader from "components/SkeletonLoader";
import { VARIANT } from "common/constants";
import { findDataInScale, getAvatar } from "common/utils";
import { useEvaluationResult } from "hooks/useEvaluationResult";
import { StyledMember } from "./styles";

const EvaluationResult = (props) => {
  const {
    members, scaleIndices, isValidToStarRating, evaluationId, t,
  } = props;

  // Extract member IDs
  const evaluationResultMembersId = members.map((member) => member.id);
  const queryTeam = { q: { id_in: evaluationResultMembersId } };

  // Fetch evaluation results
  const { fetchEvaluationResults } = useEvaluationResult(t);
  const { evaluationResults, isLoading, refetch } = fetchEvaluationResults(
    queryTeam,
    evaluationId,
    true,
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    evaluationResults.map((member) => {
      const resultIndex = findDataInScale(scaleIndices, member?.scores?.basic_result);
      return (
        <Grid item xs={ 12 } key={ member.id } data-testid={ "evaluation-result-member-item" }>
          <StyledMember>
            <Avatar
              alt={ member?.evaluation_details?.evaluated?.name }
              src={ getAvatar(member?.evaluation_details?.evaluated) }
              key={ member.id }
            />
            <div>
              {isValidToStarRating ? (
                <StarRating
                  name={ `star-rating-${member.id}` }
                  value={ resultIndex?.position }
                  isReadOnly
                  maxRating={ scaleIndices?.length }
                  max={ scaleIndices?.length }
                  precision={ 1 }
                  hasNoFormattedValue
                  label={ resultIndex?.result }
                  size={ "small" }
                />
              ) : (
                <Typography variant={ VARIANT.h6 }>{member?.result}</Typography>
              )}
              <Typography>{member?.evaluation_details?.evaluated?.name}</Typography>
            </div>
          </StyledMember>
        </Grid>
      );
    })
  );
};

EvaluationResult.propTypes = {
  members: PropTypes.array.isRequired,
  scaleIndices: PropTypes.array.isRequired,
  isValidToStarRating: PropTypes.bool.isRequired,
  evaluationId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default EvaluationResult;
