import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import theme from "theme";

export const StyledAccordionContainer = styled(Accordion)`
  box-shadow: ${theme.palette.boxShadow.default};
  &. Mui-expanded{
    margin: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${theme.palette.background.purple};
  min-height: 40px;
  margin-top: 5px;
  border-radius: 4px;
  svg {
    color: white;
  }
  .disabledIcon {
    opacity: 0;
  }
  p {
    font-weight: 700;
  }
  p, h4 {
    color: white;
  }
  h4 {
    margin-left: 10px;
  }
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  background: #C4C4C4;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  border: none !important;
  color: ${(props) => (props.active ? "white" : "#4F4F4F")};
  background-color: ${(props) => (props.active ? props.theme.palette.background.purple : "transparent")};
  text-transform: none;
  padding: 8px 10px;
  margin: ${(props) => (props.active ? "-4px" : "0")};
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 5px !important;
  transition: padding 0.3s ease, margin 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? props.theme.palette.background.purple : "#A2A2A2")};
    color: white;
  }
`;

export const StyledGridContentContainer = styled(Grid)`
  margin-top: 12px;
  .MuiButtonBase-root {
    background-color: #F0E3FF;
    margin-bottom: 10px;
    h5 {
      font-size: 12px;
    }
  }
  .MuiAccordionSummary-content {
    margin: 5px 0;
  }
  .Mui-expanded {
    margin: 0;
  }
  .MuiCollapse-vertical {
    padding: 0 5px 8px;
  }
`;

export const StyledDescription = styled.div`
  background: #E6EBF3;
  margin: 10px 0;
  padding: 10px 20px;
  border-radius: 4px;
  white-space: pre-line;
  p, h1, h2, h3, h4, h5, h6 {
    background: none;
    margin: 0;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  border: 0;
  padding: 0;
  p {
    padding: 5px;
  }
  p:nth-of-type(odd) {
    background-color: #E6EBF3;
  }

  p:nth-of-type(even) {
    background-color: #E0E5EC;
  }
`;

export const StyledAnsweredBy = styled.span`
  display: flex;
  font-size: 10px;
  justify-content: right;
  font-weight: 400;
`;
