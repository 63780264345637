import {
  useState, useEffect, useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PersonSearch from "@mui/icons-material/PersonSearch";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AlarmIcon from "@mui/icons-material/Alarm";
import SearchIcon from "@mui/icons-material/Search";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import EditIcon from "@mui/icons-material/Edit";
import RestoreIcon from "@mui/icons-material/Restore";
import GroupIcon from "@mui/icons-material/Group";
import { ReactComponent as ResultScaleIcon } from "assets/images/planning/stars.svg";
import InputTag from "components/InputTag";
import InputTextController from "components/InputTextController";
import InputNumberController from "components/InputNumberController";
import DateInputController from "components/DateInputController";
import SelectController from "components/SelectController";
import { getAudience } from "redux/actions/audience/audienceActions";
import SkeletonLoader from "components/SkeletonLoader";
import TitleIcon from "components/TitleIcon";
import Button from "components/Button";
import AlertDialog from "components/AlertDialog";
import SimpleAlert from "components/SimpleAlert";
import TooltipIcon from "components/TooltipIcon";
import Alert from "components_refactor/Alert";
import {
  OBJECT_KEYS, DATE_PARTS, TYPES, SIZE, SKELETONS_NUMBER, VARIANT,
  TARGET, BUTTON_STYLE_TYPES, SURVEY_PROCESS_TYPE, ICON_TYPES, INFO,
} from "common/constants";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import { getTemplateListByProcess } from "redux/actions/template/mandrillTemplateActions";
import { createParticipationSchema } from "redux/actions/surveyProcessesActions";
import { getCurrentLanguage, compareProps } from "common/utils";
import { isNotValid } from "common/helpers";
import { getFormattedAudiencesTags } from "views/Planning/functions";
import Stakeholders from "./components/Stakeholders";
import Goals from "./components/Goals";
import NotificationAccordion from "./components/NotificationAccordion";
import TotalAudience from "./components/TotalAudience";
import FormStepper from "./components/FormStepper";
import ModalAudience from "./components/ModalAudience";
import ResultScaleTable from "./components/ResultScaleTable";
import {
  titleLanguages,
  getFormattedTemplatesList,
  iconStyles,
  processStates,
  getFormattedResultScaleList,
} from "../../functions";
import {
  StyledInputContainer,
  StyledInputNumberContainer,
  StyledTemplateTitle,
} from "../../styles";
import {
  StyledButton,
  StyledReminder,
  StyledCustomButton,
  StyledStakeholderContainer,
  StyledEditTitle,
  StyledPaper,
  StyledRestoreButton,
  StyledLink,
  StyledCheckbox,
  StyledCalibration,
  StyledGoalsContainer,
} from "./styles";

const ProcessData = (props) => {
  const {
    t,
    dataStates,
    control,
    process,
    processId,
    handleAddModal,
    audienceSelected,
    audiencesList,
    handleCloseModal,
    handleTags,
    isLoading,
    isLoadingAudienceList,
    templatesList,
    isLoadingTemplatesList,
    isFetchingTemplatesList,
    templateSelected,
    setFormValues,
    handleCustomizeProcess,
    templateData,
    isLoadingTemplate,
    isFetchingTemplate,
    formValues,
    evaluations,
    resultScaleList,
    isLoadingScaleList,
    resultScaleSelected,
    saveResultScale,
    setFormStates,
    surveyResultDialog,
    restoreSurveyResultDialog,
    restoreResultScale,
    handleOpenModal,
    saveDraft,
    defaultValues,
    dialogUpdate,
    setDialogUpdate,
    isLoadingUpdate,
    setIsLoadingUpdate,
    setDataStates,
    user,
  } = props;
  const { inputTagsSelected, isOpen } = dataStates;
  const lang = getCurrentLanguage();
  const dispatch = useDispatch();

  const {
    successProcessSchema,
    isLoadingProcessSchema,
    errorProcessSchema,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const [previewHtml, setPreviewHtml] = useState("");
  const [mandrillTemplates, setMandrillTemplates] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [dialogSchema, setDialogSchema] = useState(false);
  const [isGoalsActive, setIsGoalsActive] = useState(formValues.goalsData?.isGoalsActive || false);
  const hasParticipationSchema = Boolean(formValues.participation_schema);
  const isNalaProtected = hasParticipationSchema
    ? formValues.participation_schema.nala_protected
    : true;
  const isNotValidToUpdateSchema = formValues?.state === processStates.running
  || formValues?.state === processStates.finished;

  const handlePreview = () => {
    const templateType = Object.values(SURVEY_PROCESS_TYPE).find(
      (processType) => processType.template === templateSelected.type,
    );
    const isDraftedProcess = !processId
    || (processId && formValues?.state === processStates.drafted);
    const url = isDraftedProcess ? "preview" : "survey_process";
    const templateUrl = `/planning/${url}?${templateType.value}=${isDraftedProcess ? templateSelected.id : processId}`;
    window.open(templateUrl, TARGET.blank);
  };

  const handlePreviewWithGoal = () => {
    const templateType = Object.values(SURVEY_PROCESS_TYPE).find(
      (processType) => processType.template === templateSelected.type,
    );
    const templateUrl = `/planning/preview?${templateType.value}=${templateSelected.id}&section_type=goals`;
    window.open(templateUrl, TARGET.blank);
  };

  useEffect(() => {
    setIsGoalsActive(formValues.goalsData?.isGoalsActive || false);
  }, [formValues.goalsData?.isGoalsActive]);

  const getMandrillTemplates = useCallback(async () => {
    const templates = await dispatch(getTemplateListByProcess(process.mandrillLabel));
    setMandrillTemplates(templates);
  });

  useEffect(() => {
    getMandrillTemplates();
  }, [process]);

  const setSurveyResultDialog = (dialogResult) => {
    setFormStates({
      surveyResultDialog: dialogResult,
    });
  };

  const setRestoreSurveyResultDialog = (dialogResult) => {
    setFormStates({
      restoreSurveyResultDialog: dialogResult,
    });
  };
  const handleGoalsCheckboxChange = (event) => {
    const { checked } = event.target;
    setIsGoalsActive(checked);

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      goals_active: checked,
      goalsData: {
        ...prevFormValues.goalsData,
        isGoalsActive: checked,
      },
    }));
  };

  const handleSaveProcess = () => {
    if (templateSelected?.id) {
      setDialog(true);
    } else {
      const title = t("planning:steps.participation.actions.alert.draft.invalid.title");
      const message = t("planning:steps.participation.actions.alert.draft.invalid.message");
      toast(MESSAGE_TYPES.error, { title, message });
    }
  };

  const saveProcess = () => {
    setDialog(false);
    saveDraft();
  };

  const handleParticipation = () => {
    const propsToCompare = ["start_date", "end_date", "survey_process_template_id", "min_worked_days"];
    if (audienceSelected?.id || audienceSelected === null) {
      propsToCompare.push("audience_id");
      formValues.audience_id = audienceSelected?.id || audienceSelected;
    }
    const isValidToShowParticipation = compareProps(defaultValues, formValues, propsToCompare);
    // A new schema is created if any parameter affecting the audience is changed
    if (isValidToShowParticipation) {
      handleOpenModal("/planning", defaultValues?.id, defaultValues);
    } else {
      setDialogUpdate(true);
    }
  };

  const updateProcess = () => {
    setIsLoadingUpdate(true);
    saveDraft();
  };

  // ParticipationSchema
  useEffect(() => {
    if (successProcessSchema || errorProcessSchema) {
      setDialogSchema(false);
    }
    if (successProcessSchema) {
      setFormValues({
        ...formValues,
        participation_schema: successProcessSchema,
      });
    }
    // eslint-disable-next-line
  }, [successProcessSchema, errorProcessSchema]);

  const handleCreateParticipationSchema = async () => {
    dispatch(createParticipationSchema(formValues.id, true));
  };

  const [resetStep, setResetStep] = useState(false);
  const [resetStepIndex, setResetStepIndex] = useState(0);

  const handleResetStep = (step) => {
    setResetStepIndex(step);
    setResetStep(true);
  };

  useEffect(() => {
    if (resetStep) {
      setResetStep(false);
    }
  }, [resetStep]);

  const refetchAudienceData = async () => {
    const updatedData = await dispatch(getAudience(audienceSelected.id));
    const formattedData = getFormattedAudiencesTags([updatedData]);
    setFormStates({
      audienceSelected: formattedData[0],
    });
    setDataStates({
      inputTagsSelected: [formattedData[0]],
    });
  };

  const handleCheckboxChange = (event) => {
    setFormValues({
      ...formValues,
      calibration_activated: event.target.checked,
    });
  };

  const processEvaluations = evaluations || templateData?.evaluation_templates;
  const hasManagerEvaluation = processEvaluations ? processEvaluations.some((evaluation) => evaluation.type.includes("ManagerEvaluation")) : false;
  const processName = process?.dashboard;

  const handleGoalsChange = useCallback((updatedValues) => {
    const evaluationScaleId = updatedValues.evaluationForm === "scale" && updatedValues.evaluationScale
      ? updatedValues.evaluationScale
      : null;

    const dateRange = `[${updatedValues.startDate}, ${updatedValues.endDate}]`;

    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      goal_daterange: dateRange,
      goalsData: {
        ...prevFormValues.goalsData,
        isGoalsActive,
        evaluationSectionDetailTemplatesAttributes: updatedValues.stakeholders,
        weighing: updatedValues.goalWeight,
        startDate: updatedValues.startDate,
        endDate: updatedValues.endDate,
        evaluationScaleId,
      },
    }));
  }, [setFormValues, processEvaluations, isGoalsActive]);

  const resultScaleByProcess = getFormattedResultScaleList(resultScaleList, process);
  const steps = [
    {
      key: OBJECT_KEYS.template,
      icon: process?.icon,
      content: isLoadingTemplatesList ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } columnWidth={ 12 } />
      ) : (
        <>
          <Grid container spacing={ 3 }>
            {!(formValues?.state === processStates.drafted || !formValues?.state) && (
              <Grid item xs={ 12 }>
                <SimpleAlert
                  type={ INFO }
                  message={ t("planning:alert.noEditTemplate") }
                />
              </Grid>
            )}
            <Grid item xs={ 8 }>
              <Typography>
                {t(`planning:steps.template.subtitle.${process.itemData.toLowerCase()}.line1.text1`)}
                <strong>{t(`planning:steps.template.subtitle.${process.itemData.toLowerCase()}.line1.text2`)}</strong>
                {t(`planning:steps.template.subtitle.${process.itemData.toLowerCase()}.line1.text3`)}
              </Typography>
              <Typography>
                {t(`planning:steps.template.subtitle.${process.itemData.toLowerCase()}.line2.text1`)}
                <strong>{t(`planning:steps.template.subtitle.${process.itemData.toLowerCase()}.line2.text2`)}</strong>
              </Typography>
            </Grid>
            <StyledInputContainer item xs={ 4 }>
              <SelectController
                id={ OBJECT_KEYS.surveyProcessTemplateId }
                name={ OBJECT_KEYS.surveyProcessTemplateId }
                label={ t("common:common.select") }
                control={ control }
                menuItems={ getFormattedTemplatesList(templatesList, process) }
                sx={ { width: "100%" } }
                defaultValue={ templateSelected?.id ?? "" }
                disabled={ !(formValues?.state === processStates.drafted || !formValues?.state) }
              />
            </StyledInputContainer>
          </Grid>
          {(isLoadingTemplate
            || isFetchingTemplate
            || isFetchingTemplatesList
          ) && <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } /> }
          {!isFetchingTemplate
            && !isFetchingTemplatesList
            && templateSelected
            && templateData
            && templateData?.id === templateSelected?.id && (
            <>
              <StyledTemplateTitle variant={ VARIANT.h4 }>
                {!isFetchingTemplate && templateSelected[`name_${lang}`]}
              </StyledTemplateTitle>
              <Typography>{templateSelected[`description_${lang}`]}</Typography>
              <Grid item xs={ 6 }>
                <Box display={ "flex" } justifyContent={ "flex-start" }>
                  <StyledButton
                    onClick={ handlePreview }
                    variant={ VARIANT.contained }
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  >
                    <SearchIcon />
                    {t("planning:preview_process")}
                  </StyledButton>
                  {process.isValidToCustomProcess
                  && (formValues?.state === processStates.drafted || !formValues?.state)
                  && (
                    <StyledCustomButton
                      onClick={ handleCustomizeProcess }
                      variant={ VARIANT.outlined }
                      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    >
                      <EditIcon />
                      {t("planning:customize_process")}
                    </StyledCustomButton>
                  )}
                </Box>
              </Grid>
            </>
          )}
          {(evaluations || templateData?.evaluation_templates)
              && process.isValidToStakeholders
              && (
                <StyledStakeholderContainer item xs={ 12 }>
                  <Stakeholders
                    { ...props }
                    evaluations={ evaluations || templateData?.evaluation_templates }
                    formLanguage={ lang }
                    user={ user }
                  />
                </StyledStakeholderContainer>
              )}
          {(process.processType === "performance" && templateSelected) && (
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Box display={ "flex" } alignItems={ "center" }>
                  <Checkbox
                    checked={ isGoalsActive }
                    onChange={ handleGoalsCheckboxChange }
                    disabled={ !(formValues?.state === processStates.drafted
                      || !formValues?.state) }
                  />
                  <Typography variant={ VARIANT.h5 }>
                    {t("planning:steps:goals:active")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}

          {(process.processType === "performance" && templateSelected) && isGoalsActive && (
            <StyledGoalsContainer>
              <Goals
                evaluations={ processEvaluations }
                initialFormLanguage={ lang }
                user={ user }
                t={ t }
                control={ control }
                handleGoalsChange={ handleGoalsChange }
                isDisabled={ !(formValues?.state === processStates.drafted || !formValues?.state) }
                formValues={ formValues }
              />
              {!formValues?.state
                  && (
                    <Box display={ "flex" } justifyContent={ "flex-start" }>
                      <StyledButton
                        onClick={ handlePreviewWithGoal }
                        variant={ VARIANT.contained }
                        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                      >
                        <SearchIcon />
                        {t("planning:preview_process")}
                      </StyledButton>
                    </Box>
                  )}
            </StyledGoalsContainer>
          )}
        </>
      ),
      fields: [OBJECT_KEYS.surveyProcessTemplateId],
      isLoadingData: isLoadingTemplatesList,
    },
    {
      key: OBJECT_KEYS.dates,
      icon: CalendarTodayIcon,
      content: (
        <Grid container spacing={ 3 }>
          <StyledInputContainer item xs={ 12 } md={ 6 }>
            <DateInputController
              label={ t("surveys:form.fields.start_date") }
              control={ control }
              name={ OBJECT_KEYS.start_date }
              openTo={ processId === null ? DATE_PARTS.year : DATE_PARTS.day }
              value={ moment() }
              views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
              type={ TYPES.text }
              required
            />
          </StyledInputContainer>
          <StyledInputContainer item xs={ 12 } md={ 6 }>
            <DateInputController
              label={ t("surveys:form.fields.end_date") }
              control={ control }
              name={ OBJECT_KEYS.end_date }
              openTo={ DATE_PARTS.day }
              value={ moment() }
              views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
              type={ TYPES.text }
              required
            />
          </StyledInputContainer>
        </Grid>
      ),
      fields: [OBJECT_KEYS.start_date, OBJECT_KEYS.end_date],
      required: [OBJECT_KEYS.start_date, OBJECT_KEYS.end_date],
    },
    {
      key: OBJECT_KEYS.process,
      icon: ListAltIcon,
      content: (
        <StyledInputContainer item xs={ 12 }>
          {titleLanguages.map((lang) => (
            <InputTextController
              key={ `process-name-${lang.value}` }
              type={ TYPES.text }
              label={ `${t("surveys:form.fields.process_name")} (${t(`common:locales:${lang.value}`)})` }
              control={ control }
              required
              name={ OBJECT_KEYS[lang.name] }
            />
          ))}
        </StyledInputContainer>
      ),
      fields: titleLanguages.map((lang) => (OBJECT_KEYS[lang.name])),
      required: titleLanguages.map((lang) => (OBJECT_KEYS[lang.name])),
    },
    {
      key: OBJECT_KEYS.audience,
      icon: PersonSearch,
      content: isLoadingAudienceList ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } columnWidth={ 12 } />
      ) : (
        <>
          <StyledInputContainer item xs={ 12 }>
            <InputTag
              id={ OBJECT_KEYS.audienceId }
              label={ t("planning:surveys.target_audience") }
              size={ SIZE.small }
              itemsSelected={ inputTagsSelected }
              name={ OBJECT_KEYS.audienceId }
              onChange={ handleTags }
              data={ getFormattedAudiencesTags(audiencesList) }
              onAddButtonField={ handleAddModal }
              isRequired
              disableClearable
              forcePopupIcon={ false }
              isDisabled={ isNotValidToUpdateSchema }
            />
          </StyledInputContainer>
          <TotalAudience
            t={ t }
            audienceSelected={ audienceSelected }
            handleCloseModal={ handleCloseModal }
          />
        </>
      ),
      fields: [OBJECT_KEYS.audienceId],
      required: [OBJECT_KEYS.audienceId],
      isLoadingData: isLoadingAudienceList,
    },
    {
      key: OBJECT_KEYS.minWorkedDays,
      icon: AccessTimeIcon,
      content: (
        <StyledInputContainer item xs={ 12 }>
          <StyledInputNumberContainer>
            <InputNumberController
              label={ t("surveys:form.fields.days") }
              control={ control }
              name={ OBJECT_KEYS.minWorkedDays }
              isInteger
            />
          </StyledInputNumberContainer>
        </StyledInputContainer>
      ),
      fields: [OBJECT_KEYS.minWorkedDays],
    },
    {
      key: OBJECT_KEYS.notifications,
      icon: MailOutlineIcon,
      content: process === undefined ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } columnWidth={ 12 } />
      ) : (
        <Grid container spacing={ 1 }>
          <Grid item xs={ 12 }>
            <Alert
              type={ INFO }
              title={ t("planning:steps.notifications.alert") }
              message={ t("planning:steps.notifications.note") }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <NotificationAccordion
              { ...props }
              setPreviewHtml={ setPreviewHtml }
              mandrillTemplates={ mandrillTemplates }
            />
            <StyledReminder>
              <TitleIcon
                icon={ {
                  src: AlarmIcon,
                  ...iconStyles,
                } }
                iconType={ ICON_TYPES.icon }
                title={ t("planning:steps.reminder_days.title") }
              />
              <Typography>
                { t("planning:steps.reminder_days.subtitle")}
              </Typography>
              <StyledInputContainer item xs={ 12 }>
                <StyledInputNumberContainer>
                  <InputNumberController
                    label={ t("surveys:form.fields.days") }
                    control={ control }
                    name={ OBJECT_KEYS.reminderDays }
                    isInteger
                  />
                </StyledInputNumberContainer>
              </StyledInputContainer>
            </StyledReminder>
            {hasManagerEvaluation
            && (process.processType === "performance" || process.processType === "potential")
            && (
              <StyledCalibration>
                <FormControlLabel
                  label={ t("planning:steps.notifications.calibration.enable") }
                  control={ (
                    <StyledCheckbox
                      checked={ formValues?.calibration_activated }
                      onChange={ handleCheckboxChange }
                    />
                  ) }
                />
                <Alert
                  type={ INFO }
                  hasIcon={ false }
                >
                  <h3>{t("planning:steps.notifications.calibration.alert.title")}</h3>
                  <p>{`* ${t("planning:steps.notifications.calibration.alert.l1")}`}</p>
                  <p>{`* ${t("planning:steps.notifications.calibration.alert.l2", { processName })}`}</p>
                </Alert>
              </StyledCalibration>
            )}
          </Grid>
          {previewHtml
          && (
            <Grid item xs={ 12 } md={ 6 }>
              <Stack
                direction={ "row" }
                alignItems={ "flex-start" }
                spacing={ 1 }
              >
                <Box><PreviewIcon /></Box>
                <Box>
                  <Typography variant={ VARIANT.h6 }>{t("planning:preview")}</Typography>
                </Box>
              </Stack>
              <iframe
                title={ "Template" }
                style={ {
                  width: "90%", height: "600px", border: "none", transform: "scale(0.9)", transformOrigin: "0 0",
                } }
                srcDoc={ previewHtml }
              />
            </Grid>
          )}
        </Grid>
      ),
      fields: [OBJECT_KEYS.reminderDays],
    },
    {
      key: OBJECT_KEYS.resultScale,
      icon: ResultScaleIcon,
      content: isLoadingScaleList ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } columnWidth={ 12 } />
      ) : (
        <Grid container spacing={ 2 }>
          <StyledInputContainer item xs={ 3 }>
            <SelectController
              id={ OBJECT_KEYS.resultScaleId }
              name={ OBJECT_KEYS.resultScaleId }
              label={ t("common:common.select") }
              control={ control }
              menuItems={ resultScaleByProcess }
              sx={ { width: "100%" } }
              disabled={ resultScaleByProcess.length <= 1 }
            />
          </StyledInputContainer>
          <Grid item xs={ 9 }>
            {resultScaleSelected
            && (
              <StyledPaper elevation={ 3 }>
                <Typography variant={ "h4" }>{resultScaleSelected.name }</Typography>
                <Typography>{t("planning:result_scale.options.description") }</Typography>
                <Typography>
                  <ol>
                    <li>{t("planning:result_scale.options.firstOption", { scaleIndex: resultScaleSelected.result_scale_indices.length }) }</li>
                    <li>{t("planning:result_scale.options.secondOption") }</li>
                  </ol>
                </Typography>
                <ResultScaleTable
                  resultScaleIndices={ resultScaleSelected.result_scale_indices }
                  control={ control }
                  t={ t }
                  hasIcon={ process.processType === "performance" }
                />
                <Box display={ "flex" } justifyContent={ "flex-end" }>
                  <StyledRestoreButton
                    onClick={ () => setRestoreSurveyResultDialog(true) }
                    variant={ VARIANT.outlined }
                    type={ BUTTON_STYLE_TYPES.BUTTON }
                    typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                  >
                    {t("planning:result_scale.restore")}
                    <RestoreIcon />
                  </StyledRestoreButton>
                  <Button
                    onClick={ () => setSurveyResultDialog(true) }
                    variant={ VARIANT.outlined }
                    type={ BUTTON_STYLE_TYPES.BUTTON }
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  >
                    {t("common:common.save")}
                  </Button>
                </Box>
              </StyledPaper>
            )}
            <AlertDialog
              isOpen={ surveyResultDialog }
              onClose={ () => setSurveyResultDialog(false) }
              title={ t("planning:result_scale.alert.title") }
              message={ t("planning:result_scale.alert.description") }
              onSubmit={ saveResultScale }
            />
            <AlertDialog
              isOpen={ restoreSurveyResultDialog }
              onClose={ () => setRestoreSurveyResultDialog(false) }
              title={ t("planning:result_scale.alert.restore_title") }
              message={ t("planning:result_scale.alert.restore_description") }
              onSubmit={ restoreResultScale }
            />
          </Grid>
        </Grid>
      ),
      fields: [OBJECT_KEYS.resultScaleId],
    },
  ];

  if (process?.processType !== "engagement") {
    steps.push({
      key: OBJECT_KEYS.participation,
      icon: GroupIcon,
      content: (
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Alert
              type={ INFO }
              title={ t(`planning:steps.participation.type.${isNalaProtected ? "default" : "custom"}.title`) }
              message={ t(`planning:steps.participation.type.${isNalaProtected ? "default" : "custom"}.subtitle`) }
              hasToAction={ hasParticipationSchema }
            />
            {!isNalaProtected && !isNotValidToUpdateSchema
            && (
              <>
                <StyledLink onClick={ () => setDialogSchema(true) } >
                  {t("planning:steps.participation.actions.restart.title")}
                </StyledLink>
                <TooltipIcon
                  title={ t("planning:steps.participation.actions.restart.tooltip") }
                  isHelpIcon
                />
              </>
            )}
            <Grid item xs={ 6 }>
              <Box display={ "flex" } justifyContent={ "flex-start" } mt={ 2 }>
                <StyledButton
                  onClick={ () => (formValues?.id ? handleParticipation() : handleSaveProcess()) }
                  variant={ VARIANT.contained }
                  typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                >
                  <SearchIcon />
                  {t("planning:steps.participation.actions.edit.view")}
                </StyledButton>
                {(formValues?.state === processStates.drafted || !formValues?.state)
                  && (
                    <StyledCustomButton
                      onClick={ () => handleResetStep(0) }
                      variant={ VARIANT.outlined }
                      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    >
                      <EditIcon />
                      {t("planning:steps.participation.actions.edit.evaluations")}
                    </StyledCustomButton>
                  )}
              </Box>
            </Grid>
          </Grid>
          <AlertDialog
            isOpen={ dialog }
            onClose={ () => setDialog(false) }
            title={ t("planning:steps.participation.actions.alert.draft.valid.title") }
            message={ t("planning:steps.participation.actions.alert.draft.valid.message") }
            onSubmit={ saveProcess }
          />
          <AlertDialog
            isOpen={ dialogSchema }
            onClose={ () => setDialogSchema(false) }
            title={ t("planning:steps.participation.actions.restart.title") }
            message={ t("planning:steps.participation.actions.restart.tooltip") }
            onSubmit={ handleCreateParticipationSchema }
            buttons={ {
              isLoading: isLoadingProcessSchema,
            } }
          />
          <AlertDialog
            isOpen={ dialogUpdate }
            onClose={ () => setDialogUpdate(false) }
            title={ t("planning:steps.participation.actions.alert.update.title") }
            message={ t("planning:steps.participation.actions.alert.update.message") }
            onSubmit={ updateProcess }
            buttons={ {
              isLoading: isLoadingUpdate,
            } }
            alertMessage={ isLoadingUpdate ? {
              title: t("planning:steps.participation.actions.alert.update.alert.title"),
              message: t("planning:steps.participation.actions.alert.update.alert.message"),
            } : null }
          />
        </Grid>
      ),
      fields: [OBJECT_KEYS.participation],
    });
  }

  return (
    <Grid container spacing={ 3 } data-testid={ "process-data-view-component" }>
      { processId && formValues?.name
        && (
          <Grid item xs={ 12 }>
            <StyledEditTitle>
              <TitleIcon
                icon={ {
                  src: EditIcon,
                  ...iconStyles,
                } }
                iconType={ ICON_TYPES.icon }
                title={ formValues.name }
              />
            </StyledEditTitle>
          </Grid>
        )}
      {!isNotValid(process) && isLoading && mandrillTemplates !== null ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
      ) : (
        <FormStepper
          t={ t }
          steps={ steps }
          process={ process }
          audienceSelected={ audienceSelected }
          setFormValues={ setFormValues }
          isStepClickable={ formValues?.state_transition || formValues?.state }
          resetStep={ resetStep }
          activeStepIndex={ resetStepIndex }
        />
      )}
      {!isLoadingAudienceList && audienceSelected?.id && (
        <ModalAudience
          t={ t }
          isOpen={ isOpen }
          handleCloseModal={ handleCloseModal }
          audienceSelected={ audienceSelected }
          refetchAudienceData={ refetchAudienceData }
        />
      )}
    </Grid>
  );
};

ProcessData.propTypes = {
  control: PropTypes.object.isRequired,
  process: PropTypes.object.isRequired,
  processId: PropTypes.string,
  handleAddModal: PropTypes.func.isRequired,
  audienceSelected: PropTypes.object,
  dataStates: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleTags: PropTypes.func.isRequired,
  audiencesList: PropTypes.array,
  isLoading: PropTypes.bool,
  isLoadingAudienceList: PropTypes.bool.isRequired,
  templatesList: PropTypes.array,
  isLoadingTemplatesList: PropTypes.bool.isRequired,
  isFetchingTemplatesList: PropTypes.bool.isRequired,
  templateSelected: PropTypes.object,
  setFormValues: PropTypes.func.isRequired,
  handleCustomizeProcess: PropTypes.func.isRequired,
  templateData: PropTypes.shape({
    evaluation_templates: PropTypes.array,
    id: PropTypes.number,
  }),
  isLoadingTemplate: PropTypes.bool,
  isFetchingTemplate: PropTypes.bool,
  formValues: PropTypes.object,
  evaluations: PropTypes.array,
  resultScaleList: PropTypes.array.isRequired,
  isLoadingScaleList: PropTypes.bool.isRequired,
  resultScaleSelected: PropTypes.object.isRequired,
  saveResultScale: PropTypes.func.isRequired,
  setFormStates: PropTypes.func.isRequired,
  surveyResultDialog: PropTypes.bool.isRequired,
  restoreSurveyResultDialog: PropTypes.bool.isRequired,
  restoreResultScale: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  saveDraft: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  dialogUpdate: PropTypes.bool.isRequired,
  setDialogUpdate: PropTypes.func.isRequired,
  isLoadingUpdate: PropTypes.bool.isRequired,
  setIsLoadingUpdate: PropTypes.func.isRequired,
  setDataStates: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

ProcessData.defaultProps = {
  processId: null,
  audiencesList: null,
  isLoading: false,
  templatesList: null,
  templateData: null,
  isLoadingTemplate: false,
  isFetchingTemplate: false,
  formValues: {},
  evaluations: null,
  defaultValues: null,
  audienceSelected: null,
  templateSelected: null,
};

export default ProcessData;
