import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import charts from "theme/charts";
import { getAvatar } from "common/utils";
import { ALIGN_ITEMS, SINGLE_PROFILE_PATHS } from "common/constants";

import {
  StyledBoxContainer,
  StyledImageContainer,
  StyledStatus,
  StyledTitleWithSubtitle,
  StyledEmailIcon,
  StyledTitle,
  StyledSubtitle,
  StyledLink,
  StyledText,
} from "./styles";

const ProfileInfo = (props) => {
  const {
    collaborator,
    hasStatus,
    isShortVersion,
    customLink,
    isModalVersion,
    width,
    height,
    type,
    canViewProfile,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const getProfileUrl = (employeeId, route) => `${route}?${SINGLE_PROFILE_PATHS[type].parameter}=${employeeId}`;

  return (
    <Box display={ ALIGN_ITEMS.flex }>
      <Box flexGrow={ 1 }>
        <StyledBoxContainer>
          <StyledImageContainer width={ width } height={ height }>
            <Avatar src={ getAvatar(collaborator) } />
            {hasStatus
              && (
                <StyledStatus isActive={ collaborator.is_active }>
                  {collaborator.is_active ? <Check /> : <Clear />}
                </StyledStatus>
              )}
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <StyledLink
              href={ canViewProfile ? getProfileUrl(collaborator.id, SINGLE_PROFILE_PATHS[type].route) : "#" }
              target={ "_blank" }
              canViewProfile={ canViewProfile }
              rel={ "noopener noreferrer" }
            >
              <StyledTitle size={ isModalVersion ? 16 : null }>
                {collaborator.full_name}
              </StyledTitle>
            </StyledLink>
            {isShortVersion && isMobile
            && (
              <StyledText>
                {collaborator?.position_name}
              </StyledText>
            )}
            {isModalVersion
            && (
              <StyledText size={ 14 }>
                {collaborator?.job_position?.position_name || collaborator?.position_name}
              </StyledText>
            )}
            {collaborator?.email && !isShortVersion
            && (
              <StyledSubtitle>
                <StyledEmailIcon />
                <a href={ `mailto:${collaborator.email}` } >{collaborator.email}</a>
              </StyledSubtitle>
            ) }
          </StyledTitleWithSubtitle>
        </StyledBoxContainer>
      </Box>
      {customLink && <Box>{customLink}</Box>}
    </Box>
  );
};

ProfileInfo.propTypes = {
  collaborator: PropTypes.object.isRequired,
  hasStatus: PropTypes.bool,
  isShortVersion: PropTypes.bool,
  customLink: PropTypes.object,
  isModalVersion: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  type: PropTypes.string,
  canViewProfile: PropTypes.bool,
};

ProfileInfo.defaultProps = {
  hasStatus: false,
  isShortVersion: false,
  customLink: null,
  isModalVersion: false,
  width: 40,
  height: 40,
  type: "collaborator",
  canViewProfile: true,
};

export default ProfileInfo;
