import { useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  getEvaluationsByProcess,
  resetStateProcess,
  getOne as getSurvey,
  getTemplate,
} from "redux/actions/surveyProcessesActions";
import { getList as getEvaluationScales } from "redux/actions/evaluationScaleActions";
import { getListPaginated } from "redux/actions/collaboratorActions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GoalPreviewImage from "assets/images/forms/goal_evaluation_preview.svg";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import SkeletonLoader from "components/SkeletonLoader";
import SelectWithImage from "components/SelectWithImage";
import Banner from "components/Banner";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import ProgressTab from "components_refactor/ProgressTab";
import Question from "components/Question";
import Button from "components/Button";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  LOCATION_PROPS,
  VARIANT,
  COLORS_NAME,
  BUTTON_STYLE_TYPES,
  ALIGN_ITEMS,
  PAGINATION,
  OBJECT_KEYS,
  SKELETONS_NUMBER,
  STATUS_KEYS,
} from "common/constants";
import {
  getPropertyByLocation,
  getSelectCollaboratorOptions,
  getScaleIndicesOrder,
  getCurrentLanguage,
} from "common/utils";
import { isEmpty } from "common/helpers";
import NoDataMessage from "components/NoDataMessage";
import { getProcessTypes } from "../Planning/functions";
import { getTabsByProcess, isSimpleTypeEvaluation } from "./functions";
import { StyledContainer as StyledTemplateContainer } from "../Planning/styles";
import {
  StyledSectionContainer,
  StyledContainer,
  StyledContent,
  StyledBoxWithBorders,
  FloatingText,
  StyledGoalImage,
  StyledPreviewContent,
  StyledGoalContainer,
} from "./styles";

const SurveyEvaluationsPreview = () => {
  const { t } = useTranslation("surveys", "common", "administrator", "planning");
  const dispatch = useDispatch();
  const theme = useTheme();
  const lang = getCurrentLanguage();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const hasGoalSection = searchParams.get("section_type") === "goals";
  const isPlanningInUrl = location.pathname.includes("planning") && location.pathname.includes("preview");
  const isSurveyInUrl = location.pathname.includes("survey_process");
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processType = locationSearch?.split("=")[0]?.replace("?", "");
  const processId = locationSearch?.split("=")[1];
  const processData = getProcessTypes(t).find((process) => processType === process.value);

  const [tabHandler, setTabHandler] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [evaluatedId, setEvaluatedId] = useState(2);
  const [slideAnswers, setSlideAnswers] = useState({});
  const [evaluationList, setEvaluationList] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const {
    evaluations,
    isLoadingEvaluations,
    successProcess,
    loadingProcess,
    one: oneResultProcess,
  } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );

  const {
    list: evaluationScales,
    isLoadingList: isLoadingEvaluationScales,
  } = useSelector(
    ({ evaluationScaleReducer }) => evaluationScaleReducer,
  );

  const {
    list: collaboratorList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const getSurveyProcess = useCallback(
    () => {
      dispatch(getSurvey(processType, processId));
    },
    [dispatch, processType, processId],
  );

  const getScalesAndCollaborators = () => {
    dispatch(getEvaluationScales());
    const query = {
      q: {
        active_in: [true],
      },
    };
    dispatch(getListPaginated(PAGINATION.next, query));
  };

  // Data by process
  const getEvaluationData = useCallback(() => {
    dispatch(getEvaluationsByProcess(processId));
    getScalesAndCollaborators();
  }, [dispatch, processId]);

  useEffect(() => {
    if (oneResultProcess && !loadingProcess) {
      getEvaluationData();
    }
  }, [loadingProcess, oneResultProcess, getEvaluationData]);

  // Template
  const { data: templateData, isLoading: isLoadingTemplate, refetch } = useQuery(
    ["templateData", processId],
    () => dispatch(getTemplate(processId)),
    {
      staleTime: Infinity,
      enabled: false, // property is used to determine if the query should be executed or not
      retry: false,
    },
  );
  const getTemplateSelected = useCallback(async () => {
    getScalesAndCollaborators();
    if (templateData) {
      const evaluationsTemplate = templateData.evaluation_templates;
      const modifiedEvaluations = evaluationsTemplate.map((evaluation) => {
        const evaluationSections = evaluation.evaluation_section_templates.map((section) => {
          const evaluationQuestions = section.evaluation_question_templates.map((question) => ({
            ...question,
          }));

          return {
            ...section,
            evaluation_questions: evaluationQuestions,
          };
        });

        evaluation.type = evaluation.type.replace("Template", "");

        return {
          ...evaluation,
          evaluation_sections: evaluationSections,
        };
      });
      setEvaluationList(modifiedEvaluations);
    }
  });

  useEffect(() => {
    if (templateData) {
      getTemplateSelected();
    }
  }, [templateData]);

  useEffect(() => {
    if (evaluationList) {
      setTabs(getTabsByProcess(evaluationList, t));
    }
    // eslint-disable-next-line
  }, [evaluationList]);

  useEffect(() => {
    if (isPlanningInUrl) {
      refetch();
    } else {
      getSurveyProcess();
    }
    // eslint-disable-next-line
  }, [processId]);

  useEffect(() => {
    if (!isLoadingEvaluations && !isLoadingEvaluationScales
      && !isEmpty(evaluations) && !isPlanningInUrl) {
      setTabs(getTabsByProcess(evaluations, t));
    }
  }, [evaluations, isLoadingEvaluations, isLoadingEvaluationScales, t, isPlanningInUrl]);

  const handleValueChanged = (value) => {
    setTabHandler(value);
    setActiveTab(0);
  };

  const {
    control, handleSubmit, reset,
  } = useForm({
    defaultValues: {
      answers: {},
    },
  });

  const handleChangeEvaluated = (id) => {
    setEvaluatedId(id);
  };

  const onSubmit = () => {
    reset();
  };

  const viewMessageAndResetData = useCallback(() => {
    dispatch(resetStateProcess());
    toast(MESSAGE_TYPES.success, handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t));
  }, [t, dispatch]);

  useEffect(() => {
    if (successProcess) {
      viewMessageAndResetData();
    }
  }, [successProcess, viewMessageAndResetData]);

  const handleSlideAnswers = (id, label) => {
    setSlideAnswers({
      ...slideAnswers,
      [id]: label,
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getSectionsByEvaluation = (evaluation) => evaluation?.evaluation_sections?.filter(
    (evalSection) => evalSection.evaluation_questions?.length > 0,
  ).map((section) => (
    <StyledSectionContainer key={ section[`name_${lang}`] }>
      <Typography variant={ VARIANT.h3 }>
        {section[`name_${lang}`]}
      </Typography>
      {section.evaluation_questions.map((question) => {
        const questionScaleId = question.evaluation_scale_id;
        const scale = evaluationScales
          .find((evalScale) => evalScale.id === questionScaleId);
        if (scale && question.scale === undefined) {
          question.scale = scale;
          question.scale.indices = getScaleIndicesOrder(scale.indices);
        }
        if (isMobile && !(question.id.toString() in slideAnswers) && question.scale) {
          handleSlideAnswers(question.id.toString(), question.scale.indices[0].answer);
        }
        if (question.scale || section.open) {
          return (
            <Question
              id={ question.id.toString() }
              title={ question.name }
              key={ question.id }
              scale={ question.scale }
              control={ control }
              handleAnswers={ handleSlideAnswers }
            />
          );
        }
        return "";
      })}
      { section.has_comments
        && (
          <Question
            id={ OBJECT_KEYS.comment }
            title={ t("surveys:form.fields.general_data.comments") }
            control={ control }
          />
        )}
    </StyledSectionContainer>
  ));

  const actionButtons = (
    <Box display={ ALIGN_ITEMS.flex } justifyContent={ ALIGN_ITEMS.flexEnd }>
      <Box mt={ 2 } ml={ 2 }>
        <Button
          onClick={ () => reset() }
          variant={ VARIANT.contained }
          color={ COLORS_NAME.primary }
          type={ BUTTON_STYLE_TYPES.CANCEL }
          typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
          isDisabled
        >
          {t("common:common.cancel")}
        </Button>
      </Box>
      <Box mt={ 2 } ml={ 2 }>
        <Button
          variant={ VARIANT.contained }
          color={ COLORS_NAME.primary }
          type={ BUTTON_STYLE_TYPES.SUBMIT }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          isDisabled
        >
          {t("common:common.save")}
        </Button>
      </Box>
    </Box>
  );

  const getEvaluated = (evaluation) => (!isSimpleTypeEvaluation(evaluation.type)
  && (
    <StyledContainer>
      <StyledContent>
        <Grid container>
          <Grid item>
            <SelectWithImage
              menuItems={ getSelectCollaboratorOptions(collaboratorList) }
              value={ evaluatedId }
              onChange={ handleChangeEvaluated }
              disabled={ isEmpty(collaboratorList) }
              label={ t("surveys:form.fields.evaluated") }
            />
          </Grid>
        </Grid>
      </StyledContent>
    </StyledContainer>
  )
  );

  const getEvaluationContent = (evaluation) => {
    let content = <NoDataMessage />;
    if (evaluation?.evaluation_sections.length > 0 && (isPlanningInUrl || oneResultProcess)) {
      content = (
        <form
          id={ `evaluation-form-${processData.label}` }
          onSubmit={ handleSubmit(onSubmit) }
        >

          { getEvaluated(evaluation) }
          <StyledContainer>
            <StyledContent>
              { getSectionsByEvaluation(evaluation) }
              { evaluation.has_comments
              && (
                <Question
                  id={ OBJECT_KEYS.comment }
                  title={ t("surveys:form.fields.general_data.general_comments_title") }
                  control={ control }
                />
              )}
              { actionButtons }
            </StyledContent>
          </StyledContainer>
        </form>
      );
    }
    return content;
  };

  const evaluationProgress = {
    completedSteps: 0,
    totalSteps: 0,
    progress: 0,
    status: t("surveys:goals.status.not_started"),
    statusKey: STATUS_KEYS.NOT_STARTED,
    isCompleted: false,
    isValidToSubmit: false,
  };

  const evaluationsPreview = (
    <div>
      <Tab
        tabs={ tabs }
        onChange={ handleValueChanged }
        tabValue={ tabHandler }
      />
      { evaluations && evaluationScales && evaluations.map((evaluation, index) => {
        const title = (oneResultProcess && oneResultProcess[processData?.itemData]
                  && oneResultProcess[processData?.itemData]?.survey_process_template_name)
          ? oneResultProcess[processData?.itemData]?.survey_process_template_name
          : processData?.label;

        const evaluationTab = {
          title,
          status: evaluationProgress.status,
          statusKey: evaluationProgress.statusKey,
          progress: evaluationProgress.progress,
          isCompleted: evaluationProgress.isCompleted,
        };

        const tabsData = [evaluationTab];
        const goalSection = evaluation?.evaluation_sections.find((section) => section.section_type === "goals");
        if (goalSection) {
          const goalTab = { ...evaluationTab };
          goalTab.title = t("surveys:goals.rate_goals");
          tabsData.push(goalTab);
        }

        return (
          <TabPanel value={ tabHandler } index={ index } key={ evaluation.type }>
            <Banner
              title={ oneResultProcess && oneResultProcess[processData?.itemData] ? oneResultProcess[processData.itemData]?.name : "" }
              text={ evaluation?.type === EVALUATION_TYPE.OVERALL ? "" : tabs[index]?.label }
              icon={ processData.icon }
            />
            <StyledSectionContainer>
              <ProgressTab
                tabs={ tabsData }
                tabValue={ activeTab }
                onChange={ handleTabChange }
              />
              <TabPanel value={ activeTab } index={ 0 }>
                {getEvaluationContent(evaluation)}
              </TabPanel>
              {goalSection
               && (
                 <TabPanel value={ activeTab } index={ 1 }>
                   <StyledGoalContainer isMobile={ isMobile }>
                     <>
                       <Typography variant={ "h5" }>
                         {t(`surveys:goals.description.${evaluation?.type === EVALUATION_TYPE.AUTOEVALUATION ? "self" : "team"}`)}
                       </Typography>
                       <StyledPreviewContent isMobile={ isMobile }>
                         <StyledGoalImage
                           src={ GoalPreviewImage }
                           alt={ "Goal evaluation preview" }
                           isMobile={ isMobile }
                         />
                         {!isMobile && (
                           <StyledBoxWithBorders>
                             <FloatingText variant={ "h6" }>
                               {t(`surveys:goals.${evaluation?.type === EVALUATION_TYPE.AUTOEVALUATION ? "self" : "team"}_detail`)}
                             </FloatingText>
                           </StyledBoxWithBorders>
                         )}
                       </StyledPreviewContent>
                     </>
                   </StyledGoalContainer>
                 </TabPanel>
               )}
            </StyledSectionContainer>
          </TabPanel>
        );
      })}
    </div>
  );

  const evaluationsTemplatePreview = (
    <div>
      <Tab
        tabs={ tabs }
        onChange={ handleValueChanged }
        tabValue={ tabHandler }
      />
      { evaluationList && evaluationScales && evaluationList.map((evaluation, index) => {
        const title = templateData ? templateData[`name_${lang}`] : processData?.label;

        const evaluationTab = {
          title,
          status: evaluationProgress.status,
          statusKey: evaluationProgress.statusKey,
          progress: evaluationProgress.progress,
          isCompleted: evaluationProgress.isCompleted,
        };

        const tabsData = [evaluationTab];
        const goalSection = evaluation?.evaluation_sections.find((section) => section.section_type === "goals") || (hasGoalSection && (evaluation?.type === EVALUATION_TYPE.AUTOEVALUATION || evaluation?.type === EVALUATION_TYPE.MANAGER));
        if (goalSection) {
          const goalTab = { ...evaluationTab };
          goalTab.title = t("surveys:goals.rate_goals");
          tabsData.push(goalTab);
        }

        return (
          <TabPanel value={ tabHandler } index={ index } key={ evaluation.type }>
            <Banner
              title={ templateData ? templateData[`name_${lang}`] : "" }
              text={ evaluation?.type === EVALUATION_TYPE.OVERALL ? "" : tabs[index]?.label }
              icon={ processData.icon }
            />
            <StyledSectionContainer>
              <ProgressTab
                tabs={ tabsData }
                tabValue={ activeTab }
                onChange={ handleTabChange }
              />
              <TabPanel value={ activeTab } index={ 0 }>
                {getEvaluationContent(evaluation)}
              </TabPanel>
              {goalSection
               && (
                 <TabPanel value={ activeTab } index={ 1 }>
                   <StyledGoalContainer isMobile={ isMobile }>
                     <>
                       <Typography variant={ "h5" }>
                         {t(`surveys:goals.description.${evaluation?.type === EVALUATION_TYPE.AUTOEVALUATION ? "self" : "team"}`)}
                       </Typography>
                       <StyledPreviewContent isMobile={ isMobile }>
                         <StyledGoalImage
                           src={ GoalPreviewImage }
                           alt={ "Goal evaluation preview" }
                           isMobile={ isMobile }
                         />
                         {!isMobile && (
                           <StyledBoxWithBorders>
                             <FloatingText variant={ "h6" }>
                               {t(`surveys:goals.${evaluation?.type === EVALUATION_TYPE.AUTOEVALUATION ? "self" : "team"}_detail`)}
                             </FloatingText>
                           </StyledBoxWithBorders>
                         )}
                       </StyledPreviewContent>
                     </>
                   </StyledGoalContainer>
                 </TabPanel>
               )}
            </StyledSectionContainer>
          </TabPanel>
        );
      })}
    </div>
  );

  const templatePreview = templateData !== undefined
    && (
      <StyledTemplateContainer>
        <div>
          <ViewHeaderTitle
            title={ `${t("planning:preview")}: ${templateData[`name_${lang}`]}` }
          />
          <Typography>
            { t("administrator:modules.surveys.actions.preview.subtitle") }
          </Typography>
        </div>
        {tabs.length > 0 && evaluationList ? evaluationsTemplatePreview
          : <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />}
      </StyledTemplateContainer>
    );

  const surveyPreview = (
    <StyledTemplateContainer>
      <div>
        <ViewHeaderTitle
          title={ `${t("planning:preview")}: ${oneResultProcess ? oneResultProcess[processData.itemData]?.name : ""}` }
        />
        <Typography>
          { t("administrator:modules.surveys.actions.preview.subtitle") }
        </Typography>
      </div>
      {evaluationsPreview}
    </StyledTemplateContainer>
  );

  let preview;

  if (isPlanningInUrl) {
    preview = templatePreview;
  } else if (isSurveyInUrl) {
    preview = surveyPreview;
  } else {
    preview = evaluationsPreview;
  }

  return (
    isLoadingEvaluations && isLoadingEvaluationScales && isLoadingTemplate ? <SkeletonLoader />
      : preview
  );
};

export default SurveyEvaluationsPreview;
