import styled from "styled-components";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  margin: 20px 0;

  h5 {
    color: white;
    text-align: center;
  }
`;

export const StyledMember = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .MuiAvatar-root {
    width: 48px;
    height: 48px;
  }
`;

export const StyledHeader = styled.div`
  text-align: center;
  background-color: ${palette.primaryApp};
  padding: 10px;
  margin-bottom: 10px;

  &.header-left {
    border-top-left-radius: 4px;
  }

  &.header-right {
    border-top-right-radius: 4px;
  }
`;

export const StyledCell = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const StyledMobileContainer = styled.div`
  text-align:center;
  legend {
    justify-content:center;
}
  .mobile-member {
    margin-bottom: 16px;
    padding: 12px;
    border: ${palette.border.card};
    border-radius: 8px;
  }

  .mobile-avatar-name {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
  }

  .mobile-rating {
    margin-top: 8px;

    h5 {
      margin-bottom: 4px;
      color: ${palette.black};
    }
  }
`;
