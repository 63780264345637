import { useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import StarRating from "components/StarRating";
import { getLabelByType } from "views/Cover/functions";
import { findDataInScale, getAvatar, getPercent } from "common/utils";
import { VARIANT } from "common/constants";
import SkeletonLoader from "components/SkeletonLoader";
import { useEvaluationResult } from "hooks/useEvaluationResult";
import {
  StyledBox,
  StyledBoxTitle,
  StyledBoxDescription,
  StyledSectionContainer,
} from "../../ResponsiveQuestions/styles";
import {
  StyledMember, StyledHeader,
  StyledContainer, StyledCell,
  StyledMobileContainer,
} from "./styles";

const GeneralEvaluationResult = (props) => {
  const {
    members, scaleIndices,
    evaluation, t,
    isMobile, type,
  } = props;

  const labels = getLabelByType(t);

  const evaluationResultMembersId = members.map((member) => member.id);
  const queryTeam = { q: { id_in: evaluationResultMembersId } };

  const { fetchEvaluationResults } = useEvaluationResult(t);

  const { evaluationResults, isLoading, refetch } = fetchEvaluationResults(
    queryTeam,
    evaluation.survey_process_id,
    true,
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  const goalWeighing = evaluation.evaluation_sections.find((evalSection) => evalSection.section_type === "goals")?.weighing;
  const basicWeighing = 1 - goalWeighing;

  return (
    <StyledSectionContainer>
      <Typography variant={ VARIANT.h4 }>
        { t("surveys:confirmAnswers") }
      </Typography>
      <StyledBox>
        <StyledBoxTitle>{ `${labels[evaluation.type]}:` }</StyledBoxTitle>
        <StyledBoxDescription variant={ VARIANT.bodyOne }>
          {t("surveys:confirmAnswersDescription", { type: t(`surveys:type.${ evaluationResults.length === 1 ? "singular" : "plural"}`) })}
        </StyledBoxDescription>
        <StyledContainer>
          {isMobile
            ? (
              <StyledMobileContainer>
                {evaluationResults.map((member) => {
                  const resultIndex = findDataInScale(scaleIndices, member?.result);
                  const resultBasicIndex = findDataInScale(
                    scaleIndices,
                    member?.scores?.basic_result,
                  );
                  const resultGoalIndex = findDataInScale(scaleIndices, member?.scores?.goal_result);

                  return (
                    <div key={ member.id } className={ "mobile-member" }>
                      <div className={ "mobile-avatar-name" }>
                        <Avatar
                          alt={ member?.evaluation_details?.evaluated?.name }
                          src={ getAvatar(member?.evaluation_details?.evaluated) }
                        />
                        <Typography>{member?.evaluation_details?.evaluated?.name}</Typography>
                      </div>
                      <div className={ "mobile-rating" }>
                        <h5>{t("surveys:goals.form.general")}</h5>
                        <StarRating
                          name={ `star-rating-general-${member.id}` }
                          value={ resultIndex?.position }
                          isReadOnly
                          maxRating={ scaleIndices?.length }
                          max={ scaleIndices?.length }
                          precision={ 1 }
                          hasNoFormattedValue
                          label={ resultIndex?.result }
                          size={ "small" }
                        />
                      </div>
                      <div className={ "mobile-rating" }>
                        <h5>{`${type} (${getPercent(basicWeighing, true)})`}</h5>
                        <StarRating
                          name={ `star-rating-performance-${member.id}` }
                          value={ resultBasicIndex?.position }
                          isReadOnly
                          maxRating={ scaleIndices?.length }
                          max={ scaleIndices?.length }
                          precision={ 1 }
                          hasNoFormattedValue
                          label={ resultBasicIndex?.result }
                          size={ "small" }
                        />
                      </div>
                      <div className={ "mobile-rating" }>
                        <h5>{`${t("goals:title")} (${getPercent(goalWeighing, true)})`}</h5>
                        <StarRating
                          name={ `star-rating-goals-${member.id}` }
                          value={ resultGoalIndex?.position }
                          isReadOnly
                          maxRating={ scaleIndices?.length }
                          max={ scaleIndices?.length }
                          precision={ 1 }
                          hasNoFormattedValue
                          label={ resultGoalIndex?.result }
                          size={ "small" }
                        />
                      </div>
                    </div>
                  );
                })}
              </StyledMobileContainer>
            )
            : (
              <>
                <Grid container>
                  <Grid item md={ 3 }>
                    <StyledHeader className={ "header-left" }>
                      <h5>{t("surveys:goals.form.general")}</h5>
                    </StyledHeader>
                  </Grid>
                  <Grid item md={ 3 }>
                    <StyledHeader>
                      <h5>{`${type} (${getPercent(basicWeighing, true)})`}</h5>
                    </StyledHeader>
                  </Grid>
                  <Grid item md={ 2 }>
                    <StyledHeader className={ "header-right" }>
                      <h5>{`${t("goals:title")} (${getPercent(goalWeighing, true)})`}</h5>
                    </StyledHeader>
                  </Grid>
                </Grid>

                {/* Members */}
                {evaluationResults.map((member) => {
                  const resultIndex = findDataInScale(scaleIndices, member?.result);
                  const resultBasicIndex = findDataInScale(scaleIndices, member?.scores?.basic_result);
                  const resultGoalIndex = findDataInScale(scaleIndices, member?.scores?.goal_result);

                  return (
                    <Grid container alignItems={ "center" } key={ member.id } mb={ 2 }>
                      <Grid item md={ 3 }>
                        <StyledMember>
                          <Avatar
                            alt={ member?.evaluation_details?.evaluated?.name }
                            src={ getAvatar(member?.evaluation_details?.evaluated) }
                          />
                          <div>
                            <StarRating
                              name={ `star-rating-general-${member.id}` }
                              value={ resultIndex?.position }
                              isReadOnly
                              maxRating={ scaleIndices?.length }
                              max={ scaleIndices?.length }
                              precision={ 1 }
                              hasNoFormattedValue
                              label={ resultIndex?.result }
                              size={ "small" }
                            />
                            <Typography>{member?.evaluation_details?.evaluated?.name}</Typography>
                          </div>
                        </StyledMember>
                      </Grid>
                      <Grid item md={ 3 }>
                        <StyledCell>
                          <StarRating
                            name={ `star-rating-performance-${member.id}` }
                            value={ resultBasicIndex?.position }
                            isReadOnly
                            maxRating={ scaleIndices?.length }
                            max={ scaleIndices?.length }
                            precision={ 1 }
                            hasNoFormattedValue
                            label={ resultBasicIndex?.result }
                            size={ "small" }
                          />
                        </StyledCell>
                      </Grid>

                      {/* Goals Rating */}
                      <Grid item md={ 2 }>
                        <StyledCell>
                          <StarRating
                            name={ `star-rating-goals-${member.id}` }
                            value={ resultGoalIndex?.position }
                            isReadOnly
                            maxRating={ scaleIndices?.length }
                            max={ scaleIndices?.length }
                            precision={ 1 }
                            hasNoFormattedValue
                            label={ resultGoalIndex?.result }
                            size={ "small" }
                          />
                        </StyledCell>
                      </Grid>
                    </Grid>
                  );
                })}
              </>
            )}
        </StyledContainer>
      </StyledBox>
    </StyledSectionContainer>
  );
};

GeneralEvaluationResult.propTypes = {
  members: PropTypes.array.isRequired,
  scaleIndices: PropTypes.array.isRequired,
  evaluation: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default GeneralEvaluationResult;
