import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Typography from "@mui/material/Typography";
import NoDataMessage from "components/NoDataMessage";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { StyledChipTag } from "styledComponents/Chip";
import { getParseValue } from "common/utils";
import AccordionRate from "../AccordionRate";
import {
  StyledButtonGroup,
  StyledButton,
  StyledMenuContainer,
  StyledLabelTypography,
  StyledGridContentContainer,
  StyledDescription,
  StyledGoalDetail,
} from "../OpenQuestions/styles";
import {
  StyledAccordionDetails, StyledAnsweredBy,
  StyledAttachment,
} from "./styles";

const GoalAnswers = (props) => {
  const { data, scaleIndices, scaleMaxIndex } = props;
  const { t } = useTranslation(["performance", "tables", "goals"]);

  const [selectedLabel, setSelectedLabel] = useState(data[0]?.label || "");

  const handleMenuClick = (label) => {
    setSelectedLabel(label);
  };

  const renderMenu = () => (
    <StyledMenuContainer>
      <StyledLabelTypography variant={ "body1" }>{t("view_by")}</StyledLabelTypography>
      <StyledButtonGroup orientation={ "horizontal" }>
        {data.map((item) => (
          <StyledButton
            key={ item.label }
            active={ selectedLabel === item.label }
            onClick={ () => handleMenuClick(item.label) }
          >
            {item.label}
          </StyledButton>
        ))}
      </StyledButtonGroup>
    </StyledMenuContainer>
  );

  const getGoalDetail = (goal) => {
    const hasDescriptionOrTags = goal.description !== null || (goal.tags && goal.tags.length > 0);
    const hasComments = goal?.answers && goal.answers.length > 0;

    if (!hasDescriptionOrTags && !hasComments) {
      return null;
    }

    return (
      <StyledGoalDetail>
        <Grid container spacing={ 2 }>
          {hasDescriptionOrTags && (
            <Grid item xs={ hasComments ? 6 : 12 }>
              {goal.description && (
                <>
                  <h5 className={ "goal-title" }>
                    {t("tables:headers.description")}
                  </h5>
                  <StyledDescription>
                    {getParseValue(goal.description)}
                  </StyledDescription>
                </>
              )}
              {goal.tags && goal.tags.length > 0 && (
                <>
                  <h5 className={ "goal-title" }>
                    {t("goals:tags.title")}
                  </h5>
                  {goal.tags.map((tag) => (
                    <StyledChipTag
                      key={ tag.name }
                      variant={ "outlined" }
                      label={ tag.name }
                      size={ "small" }
                    />
                  ))}
                </>
              )}
            </Grid>
          )}
          {hasComments && (
            <Grid item xs={ hasDescriptionOrTags ? 6 : 12 }>
              <h5 className={ "goal-title" }>
                {t("performance:comments")}
              </h5>
              <StyledAccordionDetails>
                <StyledScrollBar maxHeight={ "200px" }>
                  {goal.answers.map((answer) => (
                    <div key={ answer.id } className={ "goal-comments" }>
                      <div>
                        {answer?.attachments?.length > 0 && (
                          <div style={ {
                            display: "flex", flexDirection: "row", gap: "8px", flexWrap: "wrap",
                          } }
                          >
                            {answer.attachments.map((attachment) => (
                              <div key={ attachment.url } style={ { display: "flex", alignItems: "center" } }>
                                <StyledAttachment
                                  href={ attachment.url }
                                  target={ "_blank" }
                                  rel={ "noopener noreferrer" }
                                >
                                  <AttachFileIcon />
                                  {attachment.filename}
                                </StyledAttachment>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <Typography>
                        {answer.observation.length > 0 && `"${answer.observation}"`}
                        {answer?.answeredBy?.full_name && (
                          <StyledAnsweredBy>
                            {answer.answeredBy.full_name}
                          </StyledAnsweredBy>
                        )}
                      </Typography>
                    </div>
                  ))}
                </StyledScrollBar>
              </StyledAccordionDetails>
            </Grid>
          )}
        </Grid>
      </StyledGoalDetail>
    );
  };
  const renderGoals = () => {
    const selectedData = data.find((item) => item.label === selectedLabel);
    if (!selectedData) return null;

    return (
      <StyledGridContentContainer item xs={ 12 }>
        {selectedData.goals.length === 0 ? (
          <>
            <AccordionRate
              title={ t(
                "profile:modals.performance.performanceEvaluationNote",
              ) }
            />
            <NoDataMessage customStyles={ "noDataCustom" } />
          </>
        ) : (
          selectedData.goals.map((goal) => (
            <AccordionRate
              key={ goal.id }
              title={ goal.title }
              label={ goal?.result }
              scaleMaxIndex={ scaleMaxIndex }
              scaleIndices={ scaleIndices }
              score={ goal?.score }
              children={ getGoalDetail(goal) }
            />
          ))
        )}
      </StyledGridContentContainer>
    );
  };

  return (
    <div >
      {renderMenu()}
      {renderGoals()}
    </div>
  );
};

GoalAnswers.propTypes = {
  data: PropTypes.array,
  scaleMaxIndex: PropTypes.number,
  scaleIndices: PropTypes.array,
};

GoalAnswers.defaultProps = {
  data: [],
  scaleMaxIndex: 0,
  scaleIndices: [],
};

export default GoalAnswers;
