import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS, STATE } from "common/constants";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";

import {
  RESET_STATE_SCALE,
  GET_ONE,
  GET_ONE_LOADING,
  GET_ONE_ERROR,
} from "../actionTypes/evaluationResults";

const URL = {
  main: "/evaluation_results",
  surveyProcess: "/survey_processes",
  evaluationResults: "evaluation_results",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_SCALE,
  });
};

export const getOne = (id) => async (dispatch, getState) => {
  const { one } = getState().evaluationResultReducer;

  if (!one) {
    dispatch({ type: GET_ONE_LOADING });
    try {
      const response = await configAxios.get(`${URL.main}/${id}`);
      dispatch({
        type: GET_ONE,
        payload: response.data.evaluation_result,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_ONE_ERROR);
    }
  }
};

export const getEvaluationResult = async (id) => {
  try {
    const response = await configAxios.get(`${URL.main}/${id}`);
    return response.data.evaluation_result;
  } catch (err) {
    return err;
  }
};

export const getEvaluationResultsByQuery = async (query, processId) => {
  try {
    const response = await configAxios.get(`${URL.surveyProcess}/${processId}${URL.main}`, {
      params: { ...query },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    return response.data.evaluation_results;
  } catch (err) {
    return err;
  }
};

export const updateEvaluationResultState = async (
  processId,
  evaluationResultId,
  evaluationId,
  state = STATE.answer,
) => {
  const data = {
    evaluation_result: {
      evaluation_id: evaluationId,
      state_transition: state,
    },
  };
  try {
    const EVALUATION_RESULT_URL = `${URL.surveyProcess}/${processId}/${URL.evaluationResults}/${evaluationResultId}`;
    const response = await configAxios.put(EVALUATION_RESULT_URL, data);
    return response.data;
  } catch (err) {
    return err;
  }
};
