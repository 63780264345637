import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isNull from "lodash/isNull";
import isString from "lodash/isString";
import isEqual from "lodash/isEqual";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import SearchInput from "components/SearchInput";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import {
  ORDER_BY_NAME, SKELETONS_NUMBER,
} from "common/constants";
import { getPercent } from "common/utils";
import Legend from "./components/Legend";
import { tCellRangeColor, tCellOdd } from "./functions";
import {
  StyledContainer, StyledContent, StyledSearch, StyledTHead, StyledTHeadCell, StyledTBodyCell, StyledGridHeatMap,
} from "./styles";

const CriticAreaTable = (props) => {
  const {
    header, data, isDisabled, isLoading, resultScale,
  } = props;
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [listAux, setListAux] = useState([]);
  const { t } = useTranslation(["common", "performance"]);

  const getLabelRange = () => {
    if (!resultScale || !resultScale.result_scale_indices) return [];

    const sortedIndices = [...resultScale.result_scale_indices].sort((a, b) => a.bottom - b.bottom);

    const colors = ["tCellRed", "tCellRedLight", "tCellOrange", "tCellYellow", "tCellGreenDarker", "tCellGreen"];
    const dynamicColors = colors.slice(0, sortedIndices.length);

    return sortedIndices.map((scale, index) => ({
      bgColor: dynamicColors[index],
      label: `${(scale.bottom * 100).toFixed(0)}% - ${(scale.top * 100).toFixed(0)}%`,
    }));
  };

  const validateColor = (value, index) => {
    const cellColor = isString(value)
      ? tCellOdd(index)
      : tCellRangeColor(value, resultScale);
    return cellColor;
  };

  const getEvaluationOrPercentValue = (evaluation, opc) => {
    const isNullEvaluation = isNull(evaluation) && !opc;
    const isNullValue = isNull(evaluation) && opc;
    let evaluationPercent = "";
    if (isString(evaluation)) {
      evaluationPercent = evaluation;
    } else if (isNullValue || isNullEvaluation) {
      evaluationPercent = "n/a";
    } else {
      evaluationPercent = getPercent(evaluation, opc);
    }
    return evaluationPercent;
  };

  const handleSearch = (value) => {
    setIsSearch(!isEqual(value, ""));
    setSearch(value);
    const searchResult = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (includes(get(data[i], ORDER_BY_NAME).toLowerCase(), value)) {
          searchResult.push(data[i]);
        }
      }
    }
    setListAux(searchResult);
  };

  const emptyBody = (
    <TableBody>
      <TableRow>
        <TableCell colSpan={ 6 }>
          <NoDataMessage />
        </TableCell>
      </TableRow>
    </TableBody>
  );

  return (
    <StyledContainer>
      <StyledContent
        data-testid={ "heat-map-component" }
      >
        {isLoading ? (
          <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />
        ) : (
          <>
            <StyledSearch>
              <SearchInput
                placeholder={ t("common.search") }
                value={ search }
                onChange={ handleSearch }
                disabled={ isDisabled }
              />
            </StyledSearch>
            <Table>
              <TableHead>
                <StyledTHead>
                  {header?.map((thead) => (
                    <StyledTHeadCell key={ `thead-${thead}` }>
                      {thead}
                    </StyledTHeadCell>
                  ))}
                </StyledTHead>
              </TableHead>
              {(data && isEmpty(data)) || (isSearch && isEmpty(listAux)) ? (
                emptyBody
              ) : (
                <TableBody>
                  {data
                    && (isSearch ? listAux : data).map((evaluation) => (
                      <TableRow key={ `tbody-${evaluation.name}` }>
                        {Object.keys(evaluation).map((element, i) => (
                          <StyledTBodyCell
                            key={ `tcell-${element}` }
                            className={validateColor(getEvaluationOrPercentValue(evaluation[element], false), i)}
                          >
                            {getEvaluationOrPercentValue(
                              evaluation[element],
                              true,
                            )}
                          </StyledTBodyCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </>
        )}
      </StyledContent>
      <StyledGridHeatMap container>
        <Legend
          title={ t("performance:dashboard.value_scala") }
          data={ getLabelRange() }
        />
      </StyledGridHeatMap>
    </StyledContainer>
  );
};

CriticAreaTable.propTypes = {
  header: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  component: PropTypes.object,
  isLoading: PropTypes.bool,
  resultScale: PropTypes.object,
};

CriticAreaTable.defaultProps = {
  component: null,
  isLoading: false,
  resultScale: null,
};

export default CriticAreaTable;
