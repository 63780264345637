import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { MODULES, MODULE_ACTIONS } from "common/constants";
import PersonalCalendar from "./components/PersonalCalendar";
import TopAcknowledgments from "./components/TopAcknowledgments";
import { StyledContainer, StyledTypographyTitle } from "./styles";

const HomeView = (props) => {
  const { t, ability, companyId } = props;
  const hasAccessAcknowledgments = ability?.can(MODULE_ACTIONS.view, MODULES.acknowledgments);
  const isDisabled = companyId === 64;

  return (
    <StyledContainer>
      <StyledTypographyTitle>{t("home:title")}</StyledTypographyTitle>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } md={ 5 }>
          <PersonalCalendar { ...props } />
        </Grid>
        <Grid item xs={ 12 } md={ 7 }>
          {hasAccessAcknowledgments && !isDisabled && <TopAcknowledgments { ...props } />}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

const ruleShape = PropTypes.shape({
  actions: PropTypes.arrayOf(PropTypes.string).isRequired,
  subject: PropTypes.string.isRequired,
});

HomeView.propTypes = {
  t: PropTypes.func.isRequired,
  ability: PropTypes.shape({
    can: PropTypes.func.isRequired,
    cannot: PropTypes.func.isRequired,
    rules: PropTypes.arrayOf(ruleShape).isRequired,
  }),
  companyId: PropTypes.number,
};

HomeView.defaultProps = {
  ability: null,
  companyId: 0,
};

export default HomeView;
