import { jsPDF } from "jspdf";
import { getPeersEvaluationText } from "common/validators";
import {
  ORG_UNITS,
  ENTITY_OPTION,
  OBJECT_KEYS,
} from "common/constants";
import { mainDownloadExcel, historyPush } from "common/utils";
import { getPerformancePath } from "common/functions";
import { StyledLink } from "styledComponents/View";
import localRoutes from "../../../views_refactor/Profile/functions/routes";

export const getHeatPicker = (t) => [
  { value: ORG_UNITS.division, label: t("dashboard.division") },
  { value: ORG_UNITS.area, label: t("dashboard.area") },
  { value: ORG_UNITS.subarea, label: t("dashboard.subarea") },
  { value: ENTITY_OPTION.country, label: t("dashboard.country") },
  { value: ENTITY_OPTION.city, label: t("dashboard.city") },
];

const getFirstTH = (t, selectedOpc) => {
  let title = t("dashboard.division");
  switch (selectedOpc) {
  case "area":
    title = t("dashboard.area");
    break;
  case "subarea":
    title = t("dashboard.subarea");
    break;
  case "country":
    title = t("dashboard.country");
    break;
  case "city":
    title = t("dashboard.city");
    break;
  default:
    break;
  }
  return title;
};

export const getCriticTableHeader = (t, selectedOpc, heatMapList) => {
  const header = [getFirstTH(t, selectedOpc)];

  if (heatMapList) {
    if (heatMapList.some((heatMap) => Object.prototype.hasOwnProperty.call(heatMap, "Autoevaluation"))) {
      header.push(t("surveys:evaluation_types.self_evaluation"));
    }

    if (heatMapList.some((heatMap) => Object.prototype.hasOwnProperty.call(heatMap, "PairsEvaluation"))) {
      header.push(getPeersEvaluationText(t));
    }

    if (heatMapList.some((heatMap) => Object.prototype.hasOwnProperty.call(heatMap, "ManagerEvaluation"))) {
      header.push(t("surveys:evaluation_types.manager"));
    }

    if (heatMapList.some((heatMap) => Object.prototype.hasOwnProperty.call(heatMap, "CollaboratorsEvaluation"))) {
      header.push(t("surveys:evaluation_types.collaborators"));
    }

    if (heatMapList.some((heatMap) => Object.prototype.hasOwnProperty.call(heatMap, "InternalClientEvaluation"))) {
      header.push(t("surveys:evaluation_types.internal_client"));
    }

    if (heatMapList.some((heatMap) => Object.prototype.hasOwnProperty.call(heatMap, "goals"))) {
      header.push(t("goals:title"));
    }
  }

  header.push(t("performance:dashboard.total"));

  return header;
};

// FIXME: evaluation by process
export const downloadHeatMapExcel = (heatMapValues, t, goalsActive = false) => {
  const data = [];
  heatMapValues.forEach((heatValue) => {
    data.push({
      [t("collaborators:table.excel.department")]: heatValue?.name,
      [t("collaborators:table.table-head.manager_evaluation")]: heatValue?.ManagerEvaluation,
      [t("collaborators:table.table-head.self_evaluation")]: heatValue?.Autoevaluation,
      [getPeersEvaluationText(t)]: heatValue?.PairsEvaluation,
      [t("collaborators:table.table-head.collaborators")]: heatValue?.CollaboratorsEvaluation,
      ...(goalsActive && { [t("goals:excel_label")]: heatValue?.goals }),
      [t("collaborators:table.table-head.total_evaluation")]: heatValue?.total,
    });
  });

  mainDownloadExcel(data, "performance-heat-map");
};

export const downloadHeatMapPDF = (filter) => {
  const htmlObject = document.createElement("div");
  htmlObject.innerHTML = document.getElementById("performanceHeatMap").outerHTML;
  const select = htmlObject.getElementsByClassName("MuiGrid-grid-md-3")[0];
  const select1 = htmlObject.getElementsByClassName("MuiGrid-grid-md-3")[1];
  const thead = htmlObject.getElementsByClassName("MuiTableRow-head")[0];

  htmlObject.firstChild.style.padding = "0px";
  htmlObject.firstChild.style.paddingLeft = "30px";
  htmlObject.style.padding = "300px";
  htmlObject.style.pageBreakAfter = "auto";
  htmlObject.style.paddingTop = "0px";
  htmlObject.style.display = "flex";
  htmlObject.style.justifyContent = "center";
  thead.style.display = "none";
  select.style.display = "none";
  select1.style.display = "none";

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "px",
  });
  doc.html(htmlObject, {
    callback(doc) {
      doc.save(`heat-map-filter-by-${filter}`);
    },
  });
};

export const getTableTopsKeys = (history, processId) => [
  {
    id: OBJECT_KEYS.employeeFullName,
    label: "",
    customRender: (rowData) => (
      <StyledLink
        onClick={ () => {
          historyPush(
            history,
            localRoutes.performance,
            getPerformancePath(rowData.employee_id, processId),
          );
        } }
      >
        {rowData.employee_full_name}
      </StyledLink>
    ),
  },
  {
    id: OBJECT_KEYS.result,
    label: "",
    customRender: (rowData) => <div>{ rowData.result }</div>,
  },
];

export const allCards = (data) => [
  {
    participation: {
      total: data.finished_evaluators,
      max: data.total_evaluators,
    },
  },
];
