import styled from "styled-components";
import AccordionDetails from "@mui/material/AccordionDetails";

export const StyledAccordionDetails = styled(AccordionDetails)`
  border: 0;
  padding: 0;
  .goal-comments {
    padding: 10px;
  }
  p {
    padding-top: 10px;
  }
  .goal-comments:nth-of-type(odd) {
    background-color: #E6EBF3;
  }

  .goal-comments:nth-of-type(even) {
    background-color: #E0E5EC;
  }
`;

export const StyledAnsweredBy = styled.span`
  display: flex;
  font-size: 10px;
  justify-content: right;
  font-weight: 400;
`;

export const StyledAttachment = styled.a`
  display: flex;
  align-items: center;
  color: #0000FF;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  svg {
    width: 15px;
    height: 15px;
  }
`;
