import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import {
  EVALUATION_TYPE, ensureTemplateSuffix,
  getEvaluationType, getDefaultStakeholders,
} from "views/SurveyTemplate/functions";
import {
  StyledTitle, StyledTableCell,
  StyledTotalTableCell,
  StyledTableContainer,
  StyledTotalTitle,
  StyledSwitch,
  StyledTableRow,
  StyledInputWrapper,
  StyledTextField,
} from "./styles";

const GoalsStakeholders = ({
  t, evaluations, formLanguage, onStakeholdersChange, user, formValues, isDisabled,
}) => {
  const { i18n } = useTranslation();
  const types = {
    [EVALUATION_TYPE.AUTOEVALUATION]: "auto",
    [EVALUATION_TYPE.MANAGER]: "manager",
  };

  const [stakeholders, setStakeholders] = useState(() => {
    if (!formValues?.state || !formValues?.goal_daterange) {
      const defaultStakeholders = getDefaultStakeholders(t, user?.company?.internal_client);
      return {
        auto: { ...defaultStakeholders.auto,
          checked: true,
          weighing: undefined,
          evaluation_template_id: null },
        manager: { ...defaultStakeholders.manager,
          checked: true,
          weighing: undefined,
          evaluation_template_id: null },
      };
    }
    return {};
  });

  useEffect(() => {
    if (evaluations && evaluations.length > 0
      && formValues.goalsData?.evaluationSectionDetailTemplatesAttributes) {
      const evaluationKeys = evaluations
        .map((evaluation) => types[ensureTemplateSuffix(evaluation.type)]);

      const updatedStakeholders = {
        auto: {
          ...stakeholders.auto,
          evaluation_template_id: evaluations.find(
            (template) => template.type
            === (EVALUATION_TYPE.AUTOEVALUATION),
          )?.id || null,
          checked: false,
          type: EVALUATION_TYPE.AUTOEVALUATION,
          key: "auto",
        },
        manager: {
          ...stakeholders.manager,
          evaluation_template_id: evaluations.find(
            (template) => template.type
            === (EVALUATION_TYPE.MANAGER),
          )?.id || null,
          checked: false,
          type: EVALUATION_TYPE.MANAGER,
          key: "manager",
        },
      };
      formValues.goalsData.evaluationSectionDetailTemplatesAttributes.forEach((attr) => {
        const stakeholderKey = attr.key;

        if (updatedStakeholders[stakeholderKey]) {
          updatedStakeholders[stakeholderKey].weighing = attr.weighing;
          updatedStakeholders[stakeholderKey].checked = attr.checked;
        }
      });

      Object.keys(updatedStakeholders).forEach((key) => {
        if (!evaluationKeys.includes(key)) {
          delete updatedStakeholders[key];
        }
      });
      setStakeholders(updatedStakeholders);
    }
  }, [evaluations, formValues.goalsData?.evaluationSectionDetailTemplatesAttributes]);

  const total = Object.values(stakeholders)
    .reduce((sum, stakeholder) => sum + (Math.round(stakeholder.weighing * 100) || 0), 0);

  const handleSwitchChange = (name) => (event) => {
    const isChecked = event.target.checked;

    setStakeholders((prevState) => {
      const updatedStakeholders = {
        ...prevState,
        [name]: {
          ...prevState[name],
          checked: isChecked,
          weighing: isChecked ? prevState[name].weighing : 0,
        },
      };
      onStakeholdersChange(updatedStakeholders);
      return updatedStakeholders;
    });
  };

  const handleWeighingChange = (key) => (e) => {
    const newWeighing = parseInt(e.target.value, 10) / 100;

    setStakeholders((prevState) => {
      const updatedStakeholders = {
        ...prevState,
        [key]: { ...prevState[key], weighing: newWeighing },
      };
      onStakeholdersChange(updatedStakeholders);
      return updatedStakeholders;
    });
  };

  return (
    <StyledTableContainer component={ Paper }>
      <Table>
        <TableBody>
          <StyledTableRow>
            <TableCell />
            {Object.keys(stakeholders).map((key) => (
              <StyledTableCell key={ key }>
                <Stack direction={ "row" } justifyContent={ "center" } alignItems={ "center" } spacing={ 1 }>
                  <StyledTitle>
                    {getEvaluationType(stakeholders[key].type, i18n)[formLanguage]}
                  </StyledTitle>
                  <StyledSwitch
                    checked={ stakeholders[key].checked }
                    onChange={ handleSwitchChange(key) }
                    data-testid={ `checkbox_${key}` }
                    disabled={ isDisabled }
                  />
                </Stack>
              </StyledTableCell>
            ))}
            <StyledTotalTableCell>
              <StyledTitle>{t("planning:total")}</StyledTitle>
            </StyledTotalTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTotalTableCell>
              <StyledTitle>{t("surveys:form.fields.weighing")}</StyledTitle>
            </StyledTotalTableCell>
            {Object.keys(stakeholders).map((key) => (
              <TableCell key={ key }>
                <StyledInputWrapper>
                  <StyledTextField
                    name={ key }
                    inputProps={ { inputMode: "numeric", maxLength: 3 } }
                    value={ Math.round((stakeholders[key].weighing || 0) * 100) }
                    onChange={ handleWeighingChange(key) }
                    required
                    disabled={ isDisabled || !stakeholders[key].checked }
                    onWheel={ (e) => e.target.blur() }
                  />
                </StyledInputWrapper>
              </TableCell>
            ))}
            <TableCell>
              <StyledTotalTitle isInvalid={ total !== 100 }>
                {total}
                {"%"}
              </StyledTotalTitle>
            </TableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

GoalsStakeholders.propTypes = {
  t: PropTypes.func.isRequired,
  evaluations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(EVALUATION_TYPE)),
      weighing: PropTypes.number,
    }),
  ),
  formLanguage: PropTypes.string.isRequired,
  onStakeholdersChange: PropTypes.func.isRequired,
  user: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

GoalsStakeholders.defaultProps = {
  evaluations: [],
  user: null,
  isDisabled: false,
};

export default GoalsStakeholders;
