import styled from "styled-components";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledSectionContainer = styled.div`
  margin-top: 20px;
`;

export const StyledContainer = styled.div`
  background: ${ palette.white };
  border-radius: 5px;
  @media (max-width: 960px) {
    margin-top: 0px;
  }
`;

export const StyledContent = styled.div`
  padding: 20px 30px 10px;
  @media (max-width: 960px) {
    padding: 0px;
    .MuiAvatar-root {
      width: 40px;
      height: 40px;
    }
    .MuiTypography-body1 {
      font-size: 12px;
      line-height: 14px;
      margin-top: 4px;
      font-family: "Lato";
    }
    .MuiFormControl-root {
      margin-bottom: 0px;
    }
  }
`;

export const StyledGoalImage = styled.img`
  width: ${(props) => (props.isMobile ? "100%" : "70%")};
  height: auto;
  object-fit: cover;
`;

export const StyledPreviewContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px ${(props) => (props.isMobile ? 0 : 20)}px;
`;

export const StyledBoxWithBorders = styled.div`
  position: relative;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 50px;
  width: 100px;
  background-color: #fff;
`;

export const FloatingText = styled(Typography)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-10%);
  background-color: #fff;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  width: 250px;
  color: #565656;
`;

export const StyledGoalContainer = styled.div`
  background: white;
  border-radius: 5px;
  padding: ${(props) => (props.isMobile ? 10 : 30)}px;
`;
