import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import isUndefined from "lodash/isUndefined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Grid from "@mui/material/Grid";
import InputEditor from "components/InputEditor";
import Table from "components/Table";
import SimpleAlert from "components/SimpleAlert";
import SkeletonLoader from "components/SkeletonLoader";
import AlertDialog from "components/AlertDialog";
import TooltipIcon from "components/TooltipIcon";
import Button from "components/Button";
import InputTextController from "components/InputTextController";
import StarRating from "components/StarRating";
import { StyledChipTag } from "styledComponents/Chip";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import {
  WARNING,
  ERROR,
  BUTTON_STYLE_TYPES,
  PERCENTAGE,
  VARIANT,
  COLORS_NAME,
  SKELETONS_NUMBER,
  GOAL_RULES,
} from "common/constants";
import {
  preventEnter, getParseValue,
  getPercent, formatDate,
} from "common/utils";
import {
  create as createGoal,
  deleteItem as deleteGoalItem,
  update as updateGoal,
  resetStateProcess as resetStateGoalProcess,
} from "redux/actions/goalsActions";
import { useTags } from "hooks/useTags";
import InputTagCreator from "components_refactor/InputTagCreator";
import Comments from "../Comments";
import {
  totalCell,
  getDataEmpty,
  getTotalGoals,
  formatData,
  getUpdatedGoals,
  isUpdatedGoals,
  getGoalsHeader,
  getGoalEvaluationAnswers,
} from "../../functions";
import PeriodCollapse from "../PeriodCollapse";
import useCollapse from "./useCollapse";
import {
  useStyles,
  StyledContainer,
  StyledButton,
  StyledDescription,
  StyledCollapseData,
  StyledActionButton,
  StyledResult,
} from "./styles";

// TODO: refactor this component
const CollapseGoalsTable = (props) => {
  const {
    data, rules, isGeneral, isUpdateAllowed, isEditingDisabled,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation(["goals", "tables", "common", "collaborator", "periods"]);
  const dispatch = useDispatch();
  const {
    isLoadingProcess: isLoadingGoalProcess,
    errorProcess: errorGoalProcess,
    successProcess: successGoalProccess,
  } = useSelector(({ goalsReducer }) => goalsReducer);

  const [modal, setModal] = useState(false);
  const [modalRuleValidation, setModalRuleValidation] = useState(false);
  const [totalSum, setTotalSum] = useState();
  const [goalsEmployeeId, setGoalsEmployeeId] = useState();
  const [editData, setEditData] = useState(false);
  const [deleteValue, setDeleteValue] = useState(0);
  const [alert, setAlert] = useState({
    open: true,
    message: t("goals:alert.warning"),
    type: WARNING,
  });

  const [goalToSaveState, setGoalToSaveState] = useState();
  const [description, setDescription] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);

  const {
    control, handleSubmit, reset, watch,
  } = useForm();

  const {
    values,
    setValues,
    newData,
    setNewData,
    setNewValues,
    getGoalsGroupedByPeriods,
    periodListTransformed,
    handleDuplicate,
    editGoal,
    closeForm,
  } = useCollapse(data, t, reset);

  const handleAlert = useCallback(
    (totalSum) => {
      const itIsEqual = totalSum === PERCENTAGE.max;
      const itIsGreaterThan = totalSum > PERCENTAGE.max;
      const itIsLowerThan = totalSum < PERCENTAGE.max;
      const typeValidation = itIsGreaterThan && !itIsEqual ? ERROR : itIsLowerThan && WARNING;

      setAlert({
        open: !itIsEqual,
        message: itIsEqual ? "" : t(`goals:alert.${typeValidation}`),
        type: isUndefined(typeValidation) ? "" : typeValidation,
      });
    },
    [t],
  );

  useEffect(() => {
    totalSum && handleAlert(totalSum);
  }, [totalSum, handleAlert]);

  const sumTotal = useCallback((goalsData) => {
    const totalGoal = getTotalGoals(
      watch("goals.id"),
      watch("goals.weight"),
      goalsData || values,
    );
    setTotalSum(totalGoal);
    return totalGoal;
  }, [values, watch]);

  const updateValues = () => {
    values.pop();
    values.push(getDataEmpty(periodListTransformed), totalCell);
    setValues(values);
    editGoal(0);
  };

  useEffect(() => {
    if (newData) {
      updateValues();
    }
    // eslint-disable-next-line
  }, [newData]);

  const resetData = useCallback(
    (goalSaved) => {
      dispatch(resetStateGoalProcess());
      setValues(null);
      setNewData(false);
      const updatedGoals = getUpdatedGoals(data.goals, goalSaved, deleteValue, sumTotal);
      setNewValues(updatedGoals);
      setGoalsEmployeeId(0);
      setEditData(false);
    },
    [data, deleteValue, sumTotal, dispatch],
  );

  const { assignTagsMutation, removeTagsMutation } = useTags(t, resetData);

  const handleTagsUpdate = (goalId) => {
    const existingTags = data?.goals?.find((goal) => goal.id === goalId)?.tags || [];

    const tagsToAssign = selectedTags.filter(
      (tagName) => !existingTags.some((existingTag) => existingTag.name === tagName),
    );

    const tagsToRemove = existingTags.filter(
      (existingTag) => !selectedTags.includes(existingTag.name),
    ).map((tag) => tag.name);

    if (tagsToAssign.length > 0) {
      assignTagsMutation.mutate({ goalId, tagNames: tagsToAssign });
    }

    if (tagsToRemove.length > 0) {
      removeTagsMutation.mutate({ goalId, tagNames: tagsToRemove });
    }
    setSelectedTags(null);
  };

  const onSubmitDispatch = (goalToSave, isRuleValidationTriggered) => {
    const descriptionValue = goalToSave?.description || description;
    const goalData = {
      goal: {
        title: goalToSave.title,
        description: descriptionValue,
        employee_id: data.id,
        metric: goalToSave.metric,
        weight: goalToSave.weight,
        completed_value: goalToSave.completed_value,
        starting_date: goalToSave.starting_date,
        end_date: goalToSave.end_date,
        goal_rule_id: goalToSave.goal_rule_id,
        period_id: goalToSave.period_id,
        subperiod_id: goalToSave.subperiod_id,
      },
    };

    if (goalToSave.id !== 0) {
      dispatch(updateGoal(goalData, goalToSave.id))
        .then((updatedGoal) => selectedTags && handleTagsUpdate(updatedGoal.id));
    } else {
      dispatch(createGoal(goalData))
        .then((createdGoal) => selectedTags && handleTagsUpdate(createdGoal.id));
    }
    setEditData(true);
    setGoalsEmployeeId(data.id);
    if (isRuleValidationTriggered) {
      setModalRuleValidation(false);
    }
  };

  const validateDates = (startingDate, endDate) => {
    if (!startingDate || !endDate) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.required_dates") });
      return false;
    }

    if (new Date(endDate) <= new Date(startingDate)) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.validation_dates") });
      return false;
    }

    return true;
  };

  const onSubmit = (goalsData) => {
    if (goalsData) {
      const { starting_date: startingDate, end_date: endDate } = goalsData.goals;

      if (!validateDates(startingDate, endDate)) {
        return;
      }

      dispatch(resetStateGoalProcess());
      const goalToSave = formatData(goalsData);

      // more is better: completed_value / metric
      // less is better: (metric - completed_value) / metric + 1

      const ruleSelected = rules.filter((item) => item.id === goalToSave.goal_rule_id);

      let ruleValidation = 0;
      // more is better
      if (ruleSelected[0].name === GOAL_RULES.moreIsBetter) {
        ruleValidation = goalToSave.completed_value / goalToSave.metric;
      } else if (ruleSelected[0].name === GOAL_RULES.lessIsBetter) {
        ruleValidation = (goalToSave.metric - goalToSave.completed_value) / goalToSave.metric + 1;
      }

      ruleValidation *= 100;

      if (ruleValidation > 1000) {
        setGoalToSaveState(goalToSave);
        setModalRuleValidation(true);
      } else {
        onSubmitDispatch(goalToSave);
      }
    }
  };

  const deleteGoal = () => {
    dispatch(resetStateGoalProcess());
    setModal(false);
    setDeleteValue(deleteValue);
    dispatch(deleteGoalItem(deleteValue));
    setEditData(true);
    setGoalsEmployeeId(data.id);
  };

  const handleDeleteGoal = (id) => {
    setDeleteValue(id);
    setModal(true);
  };

  useEffect(() => {
    editData
      && isUpdatedGoals(
        goalsEmployeeId,
        data.id,
        isLoadingGoalProcess,
        successGoalProccess,
        errorGoalProcess,
      )
      && resetData(successGoalProccess);
  }, [
    successGoalProccess,
    data,
    goalsEmployeeId,
    resetData,
    editData,
    isLoadingGoalProcess,
    errorGoalProcess,
  ]);

  const validateDescription = (value) => setDescription(value);

  const handleTagsChange = (tags) => {
    setSelectedTags(tags);
  };

  const getCollapseData = (rowData) => {
    if (!rowData.goal_rule_id) return null;
    const hasDescriptionOrTags = rowData.description !== null
    || (rowData.tags && rowData.tags.length > 0);
    const hasResult = rowData?.result?.score;

    let comments = null;
    if (rowData?.result?.comments) {
      comments = getGoalEvaluationAnswers(rowData.result.comments, t);
    }

    if (!hasDescriptionOrTags && !rowData.isEdit && !hasResult) return null;
    return (
      <StyledCollapseData>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 5 }>
            {rowData.isEdit
             && (
               <InputTextController
                 required
                 type={ "text" }
                 control={ control }
                 name={ "goals.title" }
                 defaultValue={ rowData.title }
                 showHelperText={ false }
                 size={ "small" }
                 inputProps={ { maxLength: 70 } }
                 placeholder={ t("tables:headers.title") }
               />
             )}
            {rowData.isEdit ? (
              <div>
                <h4>
                  {t("goals:tags.title")}
                  <TooltipIcon
                    isHelpIcon
                  >
                    {t("goals:tags.description")}
                  </TooltipIcon>
                </h4>
                <InputTagCreator
                  onTagsChange={ handleTagsChange }
                  defaultTags={ rowData.tags || [] }
                  name={ "goals.tags" }
                />
                <h4>
                  {t("tables:headers.description")}
                  <TooltipIcon
                    isHelpIcon
                  >
                    {t("tables:helpers.description")}
                  </TooltipIcon>
                </h4>
                <Controller
                  as={ (
                    <InputEditor
                      validateInputEdit={ validateDescription }
                      text={ rowData.description }
                    />
                  ) }
                  name={ "goals.description" }
                  control={ control }
                />
                <StyledActionButton>
                  <Button
                    variant={ VARIANT.contained }
                    onClick={ () => closeForm(rowData.id) }
                    color={ COLORS_NAME.primary }
                    typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                  >
                    {t("collaborators:buttons.cancel")}
                  </Button>
                  <Button
                    variant={ VARIANT.contained }
                    color={ COLORS_NAME.primary }
                    type={ BUTTON_STYLE_TYPES.SUBMIT }
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  >
                    {t("common:common.save")}
                  </Button>
                </StyledActionButton>
              </div>
            ) : (
              <div>
                {rowData?.description
                  && (
                    <>
                      <h4>
                        {t("tables:headers.description")}
                        <TooltipIcon
                          isHelpIcon
                        >
                          {t("tables:helpers.description")}
                        </TooltipIcon>
                      </h4>
                      <StyledDescription>
                        {getParseValue(rowData.description)}
                      </StyledDescription>
                    </>
                  )}
                {rowData?.tags && rowData?.tags.length > 0 && (
                  <>
                    <h4>
                      {t("goals:tags.title")}
                      <TooltipIcon
                        isHelpIcon
                      >
                        {t("goals:tags.description")}
                      </TooltipIcon>
                    </h4>
                    {rowData.tags.map((tag) => (
                      <StyledChipTag
                        key={ tag.name }
                        variant={ "outlined" }
                        label={ tag.name }
                        size={ "small" }
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={ 12 } md={ 5 }>
            {rowData?.result && !rowData.isEdit
             && (
               <StyledResult>
                 <h3>
                   {t("goals:last_evaluation")}
                 </h3>
                 <div className={ "goal-result" }>
                   <StarRating
                     name={ `result-${rowData?.id}` }
                     value={ rowData?.result?.position }
                     isReadOnly
                     maxRating={ rowData?.result?.scale_index_count }
                     max={ rowData?.result?.scale_index_count }
                     precision={ 1 }
                     label={ rowData?.result?.result }
                     hasNoFormattedValue
                     tooltip={ rowData?.result?.score && rowData?.result?.scale_max_index ? {
                       score: getPercent(rowData?.result.score, true, 2),
                       maxScore: getPercent(rowData?.result.scale_max_index, true, 2),
                     } : null }
                     detail={ formatDate(rowData.result.date) }
                   />
                 </div>
                 {comments && <Comments data={ comments } />}
               </StyledResult>
             )}
          </Grid>
        </Grid>
      </StyledCollapseData>
    );
  };

  const showTableContent = (groupedValues) => (
    <Table
      header={ getGoalsHeader(control, rules, classes, totalSum, {
        sumTotal, editGoal, handleDeleteGoal, closeForm, handleDuplicate,
      }, t, isGeneral, periodListTransformed, isUpdateAllowed, isEditingDisabled) }
      list={ groupedValues }
      isSimpleTable
      customStyles={ classes.customTable }
      isNotPagination
      paginationOptions={ {
        maxPerPage: 100,
      } }
      getCollapseData={ getCollapseData }
      hasCustomBody
    />
  );

  return (
    <StyledContainer data-testid={ "collapse-goals-table" }>
      {(isLoadingGoalProcess && (goalsEmployeeId === data.id && values === null)) || values === null ? (
        <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FIVE } />
      ) : (
        <>
          <form
            id={ "goals-form" }
            onSubmit={ handleSubmit(onSubmit) }
            className={ classes.goalsForm }
            onKeyPress={ preventEnter }
            autoComplete={ "off" }
          >
            {isGeneral && data.goals.length > 0
              ? (
                <PeriodCollapse
                  dataByYear={ getGoalsGroupedByPeriods(data.goals) }
                  showTableContent={ showTableContent }
                />
              ) : showTableContent(values)}
          </form>
          {!isGeneral
          && (
            <StyledButton
              variant={ VARIANT.outlined }
              color={ COLORS_NAME.primary }
              isDisabled={ (totalSum === PERCENTAGE.max) || newData || isEditingDisabled }
              onClick={ () => {
                setNewData(true);
              } }
            >
              <AddCircleOutlineIcon className={ classes.addGoalIcon } />
              {t("tables:actions.add_goal")}
            </StyledButton>
          )}
          {alert.open && !isGeneral && (
            <SimpleAlert type={ alert.type } message={ alert.message } />
          )}

        </>
      )}
      {/* TODO: handle this two alert dialog as one Modal */}
      <AlertDialog
        isOpen={ modal }
        onClose={ () => setModal(false) }
        title={ t("common:common.modal_messages.sure_question") }
        message={ t("tables:confirm_actions.text_confirm") }
        onSubmit={ deleteGoal }
        buttons={ {
          isLoading: false,
        } }
      />
      <AlertDialog
        isOpen={ modalRuleValidation }
        onClose={ () => setModalRuleValidation(false) }
        title={ t("common:common.modal_messages.sure_question") }
        message={ t("goals:alert.ruleValidation") }
        onSubmit={ () => onSubmitDispatch(goalToSaveState, true) }
        buttons={ {
          isLoading: false,
        } }
      />
    </StyledContainer>
  );
};

CollapseGoalsTable.propTypes = {
  data: PropTypes.object,
  rules: PropTypes.array,
  isGeneral: PropTypes.bool,
  isUpdateAllowed: PropTypes.bool,
  isEditingDisabled: PropTypes.bool,
};

CollapseGoalsTable.defaultProps = {
  data: {},
  rules: [],
  isGeneral: false,
  isUpdateAllowed: true,
  isEditingDisabled: false,
};

export default CollapseGoalsTable;
