import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  StyledTabs, StyledText,
  StyledTab, StyledLinearProgress,
  StyledContainer, StyledProgressText,
} from "./styles";

const ProgressTab = (props) => {
  const {
    tabs, tabValue, onChange, sx,
  } = props;
  const [value, setValue] = useState(tabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(event, newValue);
  };

  useEffect(() => {
    setValue(tabValue);
  }, [tabValue]);

  return (
    <StyledTabs
      value={ value }
      onChange={ handleChange }
      sx={ sx }
    >
      {tabs.map((tab) => (
        <StyledTab
          isCompleted={ tab.isCompleted }
          key={ `progress-tab-${tab.title}` }
          label={ (
            <Box
              sx={ {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%",
                width: "100%",
              } }
            >
              <StyledContainer>
                <Box sx={ { display: "flex", alignItems: "center" } }>
                  {tab.isCompleted && (
                    <CheckCircleIcon sx={ { color: "#219653", marginRight: 1 } } />
                  )}
                  <StyledText variant={ "subtitle1" } status={ tab.statusKey }>
                    {tab.title}
                  </StyledText>
                </Box>
                <StyledProgressText variant={ "caption" } status={ tab.statusKey }>
                  {tab.status}
                </StyledProgressText>
              </StyledContainer>
              <StyledLinearProgress
                variant={ "determinate" }
                value={ tab.progress }
                sx={ {
                  width: "100%",
                  height: 5,
                  borderRadius: 1,
                } }
                status={ tab.isCompleted }
              />
            </Box>
          ) }
        />
      ))}

    </StyledTabs>
  );
};

ProgressTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      isCompleted: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  tabValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.object,
};

ProgressTab.defaultProps = {
  tabValue: 0,
  sx: {},
};

export default ProgressTab;
