import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "#000000";
const primaryApp = "#2930FF";

export default {
  black,
  white,
  primaryApp,
  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: colors.indigo[500],
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400,
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  title: {
    subtitleColor: "#444444",
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: "#2930FF",
    linkHover: "#4a50f1",
    subtitle: "#050620",
    darkPurple: "#170130",
    disabled: "#ccc",
    iconDisabled: "#A3A3A3",
    darkBlue: "#021947",
    secondaryTitle: "#333333",
    secondaryParagraph: "#75768F",
    results: "#44546A",
    transparent: "transparent",
    theadSubtitleText: "#828282",
    content: "#4f4f4f",
    inactive: "#E0E0E0",
    inactiveLight: "#EDEDED",
    purpleLight: "#E1E2FF",
    darkDisabled: "#C3C3C3",
    success: "#0CBF9D",
    gray: "#7F7F8F",
    grayDark: "#555555",
    purple: "#191EB8",
    delete: "#EC0606",
    alert: "#3C6DB8",
    create: "#282A78",
    purpleLightDark: "#6A08DA",
    purpleMaxDark: "#5113C3",
    purpleLink: "#3131A1",
    grayStrong: "#626262",
    red: "#EB5757",
    primaryInfo: "#3F0087",
    blueAction: "#00059E",
  },
  background: {
    default: "#F4F6F8",
    paper: white,
    lightGrey: "#EEE",
    lightPurple: "rgba(141, 41, 255, 0.25)",
    darkGrey: "#707070",
    lightBlack: "rgba(0, 0, 0, 0.87)",
    lightGray: "rgba(0, 0, 0, 0.04)",
    purple: "#8D29FF",
    purpleLight: "#E9D6FF",
    light: "#F0F0F7",
    lightGreyBorder: "#F0F0F0",
    grey: colors.grey[50],
    head: "#FAFAFA",
    inputTags: "#AFB1FF",
    iconTags: "#4a4ed0",
    filterButton: "#E1E2FF",
    empty: "#9C9C9C",
    mediumGrey: "#C4C4C4",
    editor: "#B9B9B9",
    simpleGrey: "#F9F9F9",
    linkButton: "rgba(63,81,181,0.08)",
    divider: "#E9E9E9",
    card: "#C9CAE9",
    gray: "#747475",
    grayCard: "#F6F6F6",
    collapse: "#F6F7FF",
    iconBtn: "#D9D9D9",
    greyMediumLight: "#E2E4E7",
    button: "#2962FF",
    newButton: "#6200EE",
    lightBlue: "#CACBFF",
    lightDark: "#DADADA",
    cyan: "#19E2D6",
    lightCyan: "rgba(25, 226, 214, 0.20)",
    purpleBox: "#9747FF",
    grayBox: "#878787",
    valid: "#7500FA",
    primaryInfo: "#EFE1FF",
    blueAction: "#E4E5FF",
    lightCard: "#F8F8FF",
    lightInfoCard: "#ECEDFA",
  },
  table: {
    header: "#2930FF",
    evenRow: "#FFFFFF",
    oddRow: "#F2F2F2",
    placeholder: "#BDBDBD",
    blue: "#2930FF",
    red: "#E53935",
    green: "#219653",
    white,
    paginationIcon: "#828282",
    disabled: "#bdbdbd",
    hover: "#F5F5F5",
    blueLight: "#2930FF66",
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  charts: {
    line: "linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1))",
    radarStroke: "#bdbdbd",
  },
  border: {
    simple: "2px solid #eee",
    simpleThin: "1px solid #EEEEEE",
    strong: "1px solid #c9c9c9",
    sidebar: ".3rem solid #2930FF",
    divider: "1px solid #CACBFF",
    light: "1px solid #DDDDDD",
    radio: "1px solid #E0DFDF",
    radioSelected: "1px solid #2930FF",
    simpleLight: "2px solid #E5E5E5",
    grayLight: "1px solid #BDBDBD",
    tabsContentTop: "2px solid #E9E9E9",
    simpleGray: "1px solid #E4E4E4",
    lightGray: "1px solid #D9D9D9",
    darkGray: "1px solid rgba(0, 0, 0, 0.23)",
    blueCard: "1px solid #2962FF",
    actionLight: "1px solid #E8E9EB",
    blueLight: "1px solid rgba(41, 48, 255, 0.4)",
    mediumCyan: "1px solid #19E2D6",
    card: "1px solid #E0E0E0",
  },
  boxShadow: {
    default: "0 0 black",
    strong: "1px 2px 5px 0px rgba(0,0,0,0.75)",
    error: "0 0 0 rgb(255,18,8)",
    card: "1px 2px 5px 0px rgba(0,0,0,0.25)",
    card2: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    card3: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    card4: "0px 6px 13px 0px rgba(0, 0, 0, 0.25)",
    radioIcon: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  iconRating: {
    default: "#2930FF",
    empty: "rgba(41, 48, 255, 0.4)",
    mediumGrey: "rgba(0,0,0,0.6)",
  },
  scroll: {
    thumbActive: "#9a9a9a",
    thumbHover: "#4c4c4c",
    boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.2)",
    firefoxColor: "#707070 #fff",
    light: "#75768F",
  },
  btnIcon: {
    color: "#19E2D6",
    backgroundLight: "rgba(41, 48, 255, 0.15)",
    backgroundDisabled: "rgba(41, 48, 255, 0.05)",
    light: "#C4C4C4",
  },
  borderTab: "1px solid #C4C4C4",
  backgroundTab: "rgba(41, 48, 255, 0.15)",
  linearProgress: {
    complete: "#19E2D6",
    incomplete: "#ECECEC",
    text: "rgba(0, 0, 0, 0.56)",
    completeLight: "rgba(25, 226, 214, 0.34)",
    completeLighter: "#B1F0EC",
    primaryColor: "#2930FF",
    negative: "#E53935",
  },
  input: {
    focused: "#3F51B5",
    disabled: "#bdbdbd",
    borderControl: "#263238",
    options: "#f4f4f4",
  },
  tooltip: {
    border: "#e0e0e0",
  },
  addIcon: "#D2D3F8",
};

export const bgColor = {
  infoHexa: "#CCEBFF",
  successHexa: "#A7FFD9",
  warningHexa: "#FFFFCA",
  errorHexa: "#FFC7C1",
  noDataHexa: "#19E2D6",
  info: "#E1E2FF",
  success: "#A7FFD9",
  warning: "#FFFFCA",
  error: "#FFC7C1",
};
export const iconColor = {
  infoHexa: "#1F24BF",
  successHexa: "#00711E",
  warningHexa: "#8A6500",
  errorHexa: "#850013",
  defaultMenu: "#00059E",
  closeHexa: "#9e9e9e",
};

export const HEAT_MAP_COLORS_RANGE = {
  critic: "#e74b39",
  low: "#eed757",
  medium: "#2d9b68",
  normal: "#66d29f",
  excellent: "#6AC4D9",
  great: "#6AC4D9",
};

export const CRITIC_COLORS_RANGE = {
  CRITIC: "#AE0000",
  LOW: "#E64A38",
  MEDIUM: "#FF7D49",
  NORMAL: "#EFD756",
  EXCELLENT: "#43B982",
};

export const RISK_CATEGORY_COLORS = {
  CRITIC: "#AE0000",
  MEDIUM: "#FFCC4D",
  EXCELLENT: "#00AE31",
};

export const LINEAL_GRAPHICS = {
  LINE_COLOR: "#8D29FF",
  BG_COLOR: "#DAB9FE",
  DOT_COLOR: "#8D29FF",
  SELECTED_DOT_COLOR: "#8D29FF",
};

export const TABLE = {
  BG_THEAD: "#F5F6FA",
  TEXT_THEAD: "#050620",
  TCELL_BORDER: "#E5E5E5",
  ODD: "#EDF0F5",
  DIVIDER: "#C4C4C4",
};

export const BODY = {
  BG_MAIN: "#F0F0F7",
};

export const STATE_COLORS = {
  SUCCESS: "#219653",
  INFO: "#2F80ED",
  GREY: "#828282",
  ERROR: "#EB5757",
};

export const RISK_COLORS = {
  RED: "#EB5757",
  YELLOW: "#FBEA8E",
  GREEN: "#68D4A2",
};

export const POTENTIAL_COLORS = {
  purple: "#8D29FF",
  orange: "#F2994A",
  green: "#139F81",
};

export const NINEBOX_COLORS = {
  lightBlue: "#2D9CDB",
  orange: "#F2994A",
  lightGreen: "#27AE60",
  lighterBlue: "#56CCF2",
  lightRed: "#F27777",
  lighterGreen: "#6FCF97",
};

export const SIGN_IN_COLORS = {
  titles: white,
  blue: "#2930FF",
  grayBackground: "#F1F1F1",
  divider: "#C4C4C4",
};

export const RECOMMENDATIONS_SECTION = {
  accordionBackground: "rgba(41,48,255,0.15)",
};

export const ENGAGEMENT = {
  default: "#8D29FF",
  unsatisfied: "#E64A38",
  neutral: "#EFD756",
  satisfied: "#43B982",
  satisfiedLight: "#68D4A2",
  neutralLight: "#FBEA8E",
  unsatisfiedLight: "#FA7E6F",
  blue: "#1F24BF",
  neutral_purple: "#B881F6",
  unsatisfied_purple: "#DBC2F9",
  selector: {
    bgDefault: "#E0E0E0",
    textDefault: "#4F4F4F",
    selectedBg: "#FFF",
    selectedFont: "#2930FF",
    selectedShadow: "rgba(0,0,0,0.29)",
  },
  row: "#F2F2F2",
  bgDefaultHeader: "#D2D3F8",
  bgPrincipal: "#2930FF",
  bgLight: "rgba(0,0,0,0.06)",
  white,
};

export const PIE_GRAPHICS_COLOR = {
  GREEN: "#27AE60",
  RED: "#EB5757",
  BLUE: "#2F80ED",
  ORANGE: "#F2994A",
};

export const TOP_BAR = {
  lightBlue: "#CACBFF",
  background: "#2930FF",
  white,
  shadow: "#0000000a",
};

export const TABLE_TOOLBAR = {
  background: "#FCC2C4",
};

export const CARD_TEXT_COLOR = {
  orange: "#CD701E",
  purple: "#191EB8",
  green: "#17928A",
  orangeDark: "#664500",
  purpleDark: "#151875",
  greenDark: "#0F6964",
};

export const CARD_RESULT = {
  purple: "rgba(141, 41, 255, 0.15)",
  blue: "rgba(41, 48, 255, 0.15)",
  green: "rgba(25, 226, 214, 0.15)",
  purpleCard: "#8D29FF",
  blueCard: "#2930FF",
  cyanCard: "#19E2D6",
  yellowCard: "#FFCC4D",
  greenCard: "#0CB2A8",
  orangeCard: "#F2994A",
  default: "rgba(255, 255, 255, 0.45)",
  colorResult: "#151522",
  normal: "#333333",
};

export const GANT_COLOR = {
  pending: "#F2994A36",
  rejected: "#F0B3A6",
  vacations: "#2930FF1C",
  medicalLicense: "#19E2D62E",
  permission: "#8D29FF30",
};

export const GANT_LETTER_COLOR = {
  pending: "#F2994A",
  rejected: "#FA0000",
  vacations: "#2930FF",
  medicalLicense: "#19B0A2",
  permission: "#7500FA",
};

export const FOLLOW_UP_STATUS_COLOR = {
  complete: "#D7E9C4",
  incomplete: "#FAD8D8",
  pending: "#FFEAB8",
  closed: "#FFEAB8",
};

export const STATE_AGREEMENT_COLOR = {
  finished: "#6FCF97",
  pending: "#E1485B",
  archieved: "#FFCC4D",
};

export const STATE_STEPPER_COLOR = {
  pending: "#BDBDBD",
  inProgress: {
    color: "#8D29FF",
    background: "#EBDCFC",
  },
  completed: {
    color: "#219653",
    background: "#CFFFE4",
  },
  sent: {
    color: "#19E2D6",
    background: "#BCF9F5",
  },
};

export const BUTTON_PROGRESS_COLORS = {
  primary: {
    main: "#2930FF",
    light: "#4a50f1",
    disable: "#7c80ff",
  },
  error: {
    main: "#E53935",
    light: "#Ef7F7D",
    disable: "#F5AEAD",
  },
};

export const PHONE_NUMBER_COLORS = {
  hoverBorder: "#b7b7b7",
  grayBorder: "#CACACA",
  alertColor: "#3C6DB8",
  alertBackground: "#E5F1FA",
};

export const EVENT_STATE_COLORS = {
  green: "#4CAF50",
  red: "#E53935",
};

export const LEADERSHIP_TYPE_COLORS = {
  development: "#FDE044",
  exemplary: "#8ABB00",
  chief: "#D07E7E",
  unproductive: "#AE0000",
};

export const GOAL_STATUS_COLORS = {
  notStarted: {
    background: "#fff",
    text: "#000",
  },
  notCompleted: {
    background: "#fff",
    text: "#000",
  },
  requiresAttention: {
    background: "#e64a38",
    text: "#fff",
  },
  goodStart: {
    background: "#fbea8e",
    text: "#000",
  },
  halfwayThere: {
    background: "#efd757",
    text: "#000",
  },
  almostThere: {
    background: "#68d5a1",
    text: "#000",
  },
  wellDone: {
    background: "#43b982",
    text: "#fff",
  },
  wonderful: {
    background: "#43b982",
    text: "#fff",
  },
};

// Alert
// FIXME: success, warning and error - colors
export const bgColorAlert = {
  info: "#EBECFF",
  success: "#A7FFD9",
  warning: "#FFFFCA",
  error: "#FFC7C1",
  primaryInfo: "#EFE1FF",
};
export const colorAlert = {
  info: "#000476",
  success: "#00711E",
  warning: "#8A6500",
  error: "#850013",
  primaryInfo: "#4D00A5",
};

// evaluation form
export const EVALUATION_COLOR = {
  not_started: {
    color: "#333333",
    background: "#FFFFFF",
  },
  in_progress: {
    color: "#2930FF",
    background: "#D2D3F8",
  },
  completed: {
    color: "#219653",
    background: "#E2EAD9",
  },
};
