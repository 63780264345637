import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import Button from "components/Button";
import { orderByDesc } from "common/helpers";
import AlertModal from "components/AlertModal";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { useEvaluationScale } from "hooks/useEvaluationScale";
import { useEvaluationResult } from "hooks/useEvaluationResult";
import GoalList from "../GoalList";
import GeneralEvaluationResult from "../GeneralEvaluationResult";
import {
  StyledContainer,
  StyledMemberSection,
  StyledStepButtons,
} from "../styles";

const SingleEvaluation = (props) => {
  const {
    t,
    member,
    isMobile,
    goalSection,
    handleToCoverOrHomeProcess,
    updateGoalProgress,
    evaluation,
    type,
    isCompletedGoal,
    isCompletedGeneral,
  } = props;

  const [currentMember, setCurrentMember] = useState(member);
  const [updateData, setUpdateData] = useState(false);
  const [showGeneralResult, setShowGeneralResult] = useState(null);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [modal, setModal] = useState(false);

  const evaluationScaleId = goalSection?.evaluation_scale_id;

  const { evaluationScale, isLoading, isError } = useEvaluationScale(evaluationScaleId);

  let scaleIndices = null;

  if (evaluationScale && !isError) {
    scaleIndices = orderByDesc(evaluationScale.indices, "top");
  }

  const handleModalSubmit = () => {
    setModal(!modal);
  };

  const handleGoalUpdate = (updatedGoalEvaluations) => {
    const updatedMember = {
      ...currentMember,
      goal_evaluations: updatedGoalEvaluations,
    };

    setCurrentMember(updatedMember);
    setUpdateData(true);
    setIsUpdateData(true);
  };

  useEffect(() => {
    if (updateData) {
      updateGoalProgress(null, currentMember);
      setUpdateData(false);
    }
    // eslint-disable-next-line
  }, [updateData]);

  const onLastGoalReached = () => {
    setShowGeneralResult(true);
  };

  const {
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const scaleProcessIndices = resultScale?.result_scale_indices;

  useEffect(() => {
    if (!isUpdateData) {
      setShowGeneralResult(isCompletedGeneral && isCompletedGoal);
    }
    // eslint-disable-next-line
  }, [isCompletedGeneral, isCompletedGoal]);

  const buttons = (
    <StyledStepButtons>
      <Button
        variant={ "outlined" }
        type={ "text" }
        onClick={ handleToCoverOrHomeProcess }
        typeStyle={ "outlined" }
      >
        {isMobile ? t("common:common.cancel") : t("surveys:continueLater")}
      </Button>
      <Button
        variant={ "outlined" }
        type={ "text" }
        onClick={ () => setShowGeneralResult(false) }
        typeStyle={ "outlined" }
      >
        {t("common:common.previous")}
      </Button>
      <Button
        isDisabled={ !(isCompletedGoal && isCompletedGeneral) }
        variant={ "contained" }
        color={ "primary" }
        typeStyle={ "submit" }
        onClick={ handleModalSubmit }
      >
        {t("common:common.submit")}
      </Button>
    </StyledStepButtons>
  );

  const { updateEvaluationResult } = useEvaluationResult(t);
  const { updateState } = updateEvaluationResult();

  const handleUpdateState = async () => {
    const processId = evaluation.survey_process_id;
    const evaluationResultId = member.id;
    const evaluationId = evaluation.id;

    await updateState({ processId, evaluationResultId, evaluationId });
    toast(MESSAGE_TYPES.success, {
      title: t("alert.success.title"),
      message: t("alert.success.message"),
    });
    handleModalSubmit();
    handleToCoverOrHomeProcess();
  };

  return showGeneralResult ? (
    <>
      <GeneralEvaluationResult
        members={ [member] }
        scaleIndices={ scaleProcessIndices }
        evaluation={ evaluation }
        t={ t }
        isMobile={ isMobile }
        type={ type }
      />
      {buttons}
      <AlertModal
        title={ t("common:common.modal_messages.sure_question") }
        text={ `${t("alert.message")} ${t("common:common.modal_messages.sure_text")}` }
        textDisagree={ t("common:common.modal_messages.no_cancel") }
        textAgree={ t("common:common.modal_messages.yes_confirm") }
        onClick={ handleUpdateState }
        open={ modal }
        handleClose={ handleModalSubmit }
        // isLoading={ isLoadingProcess }
      />
    </>
  ) : (
    <>
      <StyledContainer>
        <Grid container alignItems={ "center" } spacing={ 2 }>
          {/* Evaluated Member Section */}
          <Grid item>
            <StyledMemberSection>
              <Typography variant={ "h6" } fontWeight={ "bold" }>
                {t("surveys:form.fields.evaluated")}
              </Typography>
            </StyledMemberSection>
          </Grid>
        </Grid>
      </StyledContainer>
      {!isLoading && currentMember?.id && (
        <GoalList
          currentMember={ currentMember }
          t={ t }
          isMobile={ isMobile }
          scaleIndices={ scaleIndices }
          handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
          updateGoalEvaluations={ handleGoalUpdate }
          onLastGoalReached={ onLastGoalReached }
          isFirstMember
        />
      )}
    </>
  );
};

SingleEvaluation.propTypes = {
  evaluation: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  member: PropTypes.object,
  goalSection: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
  updateGoalProgress: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isCompletedGoal: PropTypes.bool.isRequired,
  isCompletedGeneral: PropTypes.bool.isRequired,
};

SingleEvaluation.defaultProps = {
  member: {},
};

export default SingleEvaluation;
