import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { formatDate } from "common/utils";
import {
  StyledButtonGroup,
  StyledButton,
  StyledMenuContainer,
  StyledGridContentContainer,
  StyledAccordionDetails,
  StyledAnsweredBy,
} from "./styles";

const Comments = (props) => {
  const { data } = props;
  const [selectedLabel, setSelectedLabel] = useState(data[0]?.label || "");

  const handleMenuClick = (label) => {
    setSelectedLabel(label);
  };

  const renderMenu = () => (
    <StyledMenuContainer>
      <StyledButtonGroup orientation={ "horizontal" }>
        {data.map((item) => (
          <StyledButton
            key={ item.label }
            active={ selectedLabel === item.label }
            onClick={ () => handleMenuClick(item.label) }
          >
            {item.label}
          </StyledButton>
        ))}
      </StyledButtonGroup>
    </StyledMenuContainer>
  );

  const getComments = (selectedData) => (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <StyledAccordionDetails>
          <StyledScrollBar maxHeight={ "150px" }>
            {selectedData.comments.map((answer) => (
              <Typography key={ answer.id }>
                {`"${answer.observation}"`}
                {answer?.answeredBy && (
                  <StyledAnsweredBy>
                    {`${answer.answeredBy} (${formatDate(answer.date)})`}
                  </StyledAnsweredBy>
                )}
              </Typography>
            ))}
          </StyledScrollBar>
        </StyledAccordionDetails>
      </Grid>
    </Grid>
  );
  const renderGoals = () => {
    const selectedData = data.find((item) => item.label === selectedLabel);
    if (!selectedData) return null;

    return (
      <StyledGridContentContainer item xs={ 12 }>
        {getComments(selectedData)}
      </StyledGridContentContainer>
    );
  };

  return (
    <div >
      {renderMenu()}
      {renderGoals()}
    </div>
  );
};

Comments.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Comments;
